'use client';

import { Transition } from 'react-transition-group';
import { useState, useEffect, createRef } from 'react';
import { toRaw } from 'utils/helper';
import { selectSideMenu } from 'store/sideMenuSlice';
import { useAppSelector } from 'store/hooks';
import Lucide from 'components/Lucide';
import clsx from 'clsx';
import SimpleBar from 'simplebar';
import { FormattedMenu, nestedMenu, linkTo, enter, leave } from 'utils/menu';
import Menu from './Menu';
import MenuDivider from 'components/MobileMenu/MenuDivider';
import { useLocation } from 'react-router';

import logoTextUrl from 'assets/images/logo_text_white.png';

function Main() {
  const { pathname } = useLocation();

  const location = {
    pathname: pathname,
  };

  const [formattedMenu, setFormattedMenu] = useState<Array<FormattedMenu | 'divider'>>([]);
  const sideMenuStore = useAppSelector(selectSideMenu);
  const mobileMenu = () => nestedMenu(toRaw(sideMenuStore), location);
  const [activeMobileMenu, setActiveMobileMenu] = useState(false);
  const scrollableRef = createRef<HTMLDivElement>();

  useEffect(() => {
    if (scrollableRef.current) {
      new SimpleBar(scrollableRef.current);
    }
    setFormattedMenu(mobileMenu());
  }, [sideMenuStore, location.pathname]);

  return (
    <>
      {/* BEGIN: Mobile Menu */}
      <div
        className={clsx([
          'w-full fixed bg-primary/90 z-[60] border-b border-white/[0.08] -mt-5 -mx-3 sm:-mx-8 mb-6 dark:bg-darkmode-800/90 md:hidden',
          "before:content-[''] before:w-full before:h-screen before:z-10 before:fixed before:inset-x-0 before:bg-black/90 before:transition-opacity before:duration-200 before:ease-in-out",
          !activeMobileMenu && 'before:invisible before:opacity-0',
          activeMobileMenu && 'before:visible before:opacity-100',
        ])}
      >
        <div className="h-[70px] px-3 sm:px-8 flex items-center">
          <a href="" className="flex mr-auto">
          <img alt="Chargd." className="w-40 pl-2" src={logoTextUrl} />
          </a>
          <a href="#" onClick={(e) => e.preventDefault()}>
            <Lucide
              icon="BarChart2"
              className="w-8 h-8 text-white transform -rotate-90"
              onClick={() => {
                setActiveMobileMenu(!activeMobileMenu);
              }}
            />
          </a>
        </div>
        <div
          ref={scrollableRef}
          className={clsx([
            'h-screen z-20 top-0 left-0 w-[270px] -ml-[100%] bg-primary transition-all duration-300 ease-in-out dark:bg-darkmode-800',
            '[&[data-simplebar]]:fixed [&_.simplebar-scrollbar]:before:bg-black/50',
            activeMobileMenu && 'ml-0',
          ])}
        >
          <a
            href="#"
            onClick={(e) => e.preventDefault()}
            className={clsx([
              'fixed top-0 right-0 mt-4 mr-4 transition-opacity duration-200 ease-in-out',
              !activeMobileMenu && 'invisible opacity-0',
              activeMobileMenu && 'visible opacity-100',
            ])}
          >
            <Lucide
              icon="XCircle"
              className="w-8 h-8 text-white transform -rotate-90"
              onClick={() => {
                setActiveMobileMenu(!activeMobileMenu);
              }}
            />
          </a>
          <ul className="py-2">
            {/* BEGIN: First Child */}
            {formattedMenu.map((menu, menuKey) =>
              menu == 'divider' ? (
                <MenuDivider as="li" className="my-6" key={menuKey}></MenuDivider>
              ) : (
                <li key={menuKey}>
                  <Menu
                    menu={menu}
                    formattedMenuState={[formattedMenu, setFormattedMenu]}
                    level="first"
                    setActiveMobileMenu={setActiveMobileMenu}
                  ></Menu>
                  {/* BEGIN: Second Child */}
                  {menu.subMenu && (
                    <Transition in={menu.activeDropdown} onEnter={enter} onExit={leave} timeout={300}>
                      <ul
                        className={clsx([
                          'bg-black/10 rounded-lg mx-4 my-1 dark:bg-darkmode-700',
                          !menu.activeDropdown && 'hidden',
                          menu.activeDropdown && 'block',
                        ])}
                      >
                        {menu.subMenu.map((subMenu, subMenuKey) => (
                          <li className="max-w-[1280px] w-full mx-auto" key={subMenuKey}>
                            <Menu
                              menu={subMenu}
                              formattedMenuState={[formattedMenu, setFormattedMenu]}
                              level="second"
                              setActiveMobileMenu={setActiveMobileMenu}
                            ></Menu>
                            {/* BEGIN: Third Child */}
                            {subMenu.subMenu && (
                              <Transition in={subMenu.activeDropdown} onEnter={enter} onExit={leave} timeout={300}>
                                <ul
                                  className={clsx([
                                    'bg-black/10 rounded-lg my-1 dark:bg-darkmode-600',
                                    !subMenu.activeDropdown && 'hidden',
                                    subMenu.activeDropdown && 'block',
                                  ])}
                                >
                                  {subMenu.subMenu.map((lastSubMenu, lastSubMenuKey) => (
                                    <li className="max-w-[1280px] w-full mx-auto" key={lastSubMenuKey}>
                                      <Menu
                                        menu={lastSubMenu}
                                        formattedMenuState={[formattedMenu, setFormattedMenu]}
                                        level="third"
                                        setActiveMobileMenu={setActiveMobileMenu}
                                      ></Menu>
                                    </li>
                                  ))}
                                </ul>
                              </Transition>
                            )}
                            {/* END: Third Child */}
                          </li>
                        ))}
                      </ul>
                    </Transition>
                  )}
                  {/* END: Second Child */}
                </li>
              )
            )}
            {/* END: First Child */}
          </ul>
        </div>
      </div>
      {/* END: Mobile Menu */}
    </>
  );
}

export default Main;
