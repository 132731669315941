import { useAuth0 } from '@auth0/auth0-react';
import { Navigate, Outlet, useLocation } from "react-router-dom";

const AuthenticationProtected = () => {
	const { isAuthenticated } = useAuth0();
	const location = useLocation();

	if (!isAuthenticated) {
		return <Navigate to="/login" state={{ from: location }} replace />
	}

 return (
	<Outlet />
 );
};

export default AuthenticationProtected;