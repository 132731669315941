import Chart from 'components/Chart';
import { ChartData, ChartOptions } from 'chart.js/auto';
import { Colors, getColor } from 'utils/colors';
import { selectColorScheme } from 'store/colorSchemeSlice';
import { selectDarkMode } from 'store/darkModeSlice';
import { useAppSelector } from 'store/hooks';
import { useMemo } from 'react';
import { DeepPartial } from 'chart.js/dist/types/utils';

interface MainProps extends React.ComponentPropsWithoutRef<'canvas'> {
  labels: string[];
  values: number[];
  colors: DotNestedKeys<Colors>[];
  width?: number | string;
  height: number | string;
  options?: DeepPartial<ChartOptions>;
}

function Main({ labels, values, colors, width = 'auto', height = 'auto', className, options }: MainProps) {
  const colorScheme = useAppSelector(selectColorScheme);
  const darkMode = useAppSelector(selectDarkMode);

  const chartColors = colors.map((c) => getColor(c, 0.9));
  const data: ChartData = useMemo(() => {
    return {
      labels: labels,
      datasets: [
        {
          data: values,
          backgroundColor: chartColors,
          hoverBackgroundColor: chartColors,
          borderWidth: 5,
          borderColor: darkMode ? getColor('darkmode.700') : getColor('white'),
        },
      ],
    };
  }, [values, colorScheme, darkMode]);

  const defaultOptions: ChartOptions = useMemo(() => {
    return {
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
      cutout: '80%',
    };
  }, [colorScheme, darkMode]);

  return <Chart type="doughnut" width={width} height={height} data={data} options={{...defaultOptions, ...options}} className={className} />;
}

export default Main;
