import Notes from 'features/dashboard/Notes';
import ConnectorsOverview from 'pages/dashboard/components/ConnectorsOverview';
import ChargingSessionsOverview from 'pages/dashboard/components/ChargingSessionsOverview';
import LoadCurveChart from 'pages/dashboard/components/LoadCurveChart';
import Summary from 'pages/dashboard/components/Summary';
import { ChargerApi, ReadListChargerDTO, ReadListChargerGroupDTO } from 'openapi';
import { useEffect, useState } from 'react';
import Box from 'components/Box';
import AlertComponent from 'components/Alert';
import Button from 'components/Button';
import { useNavigate } from 'react-router-dom';
import ChargersOverview from './components/ChargersOverview';

const chargerApi = new ChargerApi();

function DashboardPage() {
  const navigate = useNavigate();
  const [chargerGroups, setChargerGroups] = useState<ReadListChargerGroupDTO[]>([]);
  
  useEffect(() => {
    const getChargerGroups = async () => {
      try {
        setChargerGroups(await chargerApi.getAllChargers());
      }
      catch (ex) {

      }
    }
    getChargerGroups();
  }, []);

  return (
      <div className="grid grid-cols-12 gap-6 pt-2">
        {(chargerGroups == null || chargerGroups.length == 0) && (
          <div className="col-span-12">
          <Box title="Welcome to Chargd.">
            <AlertComponent className="mt-5">
              Welcome to the Chargd Management Console! Start by setting up and connecting your first charger.
            </AlertComponent>

            <Button variant="primary" className="mt-10" onClick={() => navigate('/chargers/add')}>
              Connect your first Charger
            </Button>
          </Box>
        </div>)}
        <div className="col-span-12">
          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-12 mt-2 xl:col-span-12 2xl:col-span-8">
              <LoadCurveChart />
            </div>
            <div className="col-span-12 mt-2 sm:col-span-6 xl:col-span-4 2xl:col-span-2 lg:mt-6 xl:mt-2">
              <ChargersOverview chargerGroups={chargerGroups} />
            </div>
            <div className="col-span-12 mt-2 sm:col-span-6 xl:col-span-4 2xl:col-span-2 lg:mt-6 xl:mt-2">
              <ConnectorsOverview />
            </div>
            <div className="col-span-12 mt-6">
              <ChargingSessionsOverview />
            </div>
            { false && <Notes />}
          </div>
        </div>
      </div>
  );
}

export default DashboardPage;
