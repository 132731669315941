/* tslint:disable */
/* eslint-disable */
/**
 * Backend.Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateOrUpdateBillingInformationDTO
 */
export interface CreateOrUpdateBillingInformationDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateBillingInformationDTO
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateBillingInformationDTO
     */
    vatNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateBillingInformationDTO
     */
    address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateBillingInformationDTO
     */
    billingEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateBillingInformationDTO
     */
    creditCardTokenId?: string | null;
}

/**
 * Check if a given object implements the CreateOrUpdateBillingInformationDTO interface.
 */
export function instanceOfCreateOrUpdateBillingInformationDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateOrUpdateBillingInformationDTOFromJSON(json: any): CreateOrUpdateBillingInformationDTO {
    return CreateOrUpdateBillingInformationDTOFromJSONTyped(json, false);
}

export function CreateOrUpdateBillingInformationDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateOrUpdateBillingInformationDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'vatNumber': !exists(json, 'vatNumber') ? undefined : json['vatNumber'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'billingEmail': !exists(json, 'billingEmail') ? undefined : json['billingEmail'],
        'creditCardTokenId': !exists(json, 'creditCardTokenId') ? undefined : json['creditCardTokenId'],
    };
}

export function CreateOrUpdateBillingInformationDTOToJSON(value?: CreateOrUpdateBillingInformationDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'vatNumber': value.vatNumber,
        'address': value.address,
        'billingEmail': value.billingEmail,
        'creditCardTokenId': value.creditCardTokenId,
    };
}

