/* tslint:disable */
/* eslint-disable */
/**
 * Backend.Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateChargerDTO
 */
export interface CreateChargerDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateChargerDTO
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateChargerDTO
     */
    model?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateChargerDTO
     */
    location?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateChargerDTO
     */
    group?: string | null;
}

/**
 * Check if a given object implements the CreateChargerDTO interface.
 */
export function instanceOfCreateChargerDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateChargerDTOFromJSON(json: any): CreateChargerDTO {
    return CreateChargerDTOFromJSONTyped(json, false);
}

export function CreateChargerDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateChargerDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'model': !exists(json, 'model') ? undefined : json['model'],
        'location': !exists(json, 'location') ? undefined : json['location'],
        'group': !exists(json, 'group') ? undefined : json['group'],
    };
}

export function CreateChargerDTOToJSON(value?: CreateChargerDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'model': value.model,
        'location': value.location,
        'group': value.group,
    };
}

