import AlertComponent from 'components/Alert';
import Lucide from 'components/Lucide';
import { twMerge } from 'tailwind-merge';

interface BoxProps extends React.PropsWithChildren, React.ComponentPropsWithoutRef<'div'> {
  title?: string;
  description?: string;// | Element;
  shadow?: boolean;
  disabled?: boolean;
  className?: string;
}

function Box({ children, title, description, className, shadow, disabled, ...props }: BoxProps) {
  return (
    <div className={twMerge([
        shadow && "intro-y mt-2 mb-10 relative before:content-[''] before:w-[90%] before:shadow-[0px_3px_20px_#0000000b] before:bg-slate-50 before:h-full before:mt-3 before:absolute before:rounded-md before:mx-auto before:inset-x-0 before:dark:bg-darkmode-400/70",
        className
        ])}>
      <div className={twMerge([
        'p-5 intro-y box',
        disabled && 'bg-slate-50'
        ])}
        {...props}>
        {title && (
          <div className="flex items-center pb-5 text-base font-medium">
            {title}
          </div>
        )}
        {description && <AlertComponent className="flex mb-5">
          <Lucide icon={'Lightbulb'} />
          <p className='ml-3'>{description}</p>
        </AlertComponent>}
        <div className={twMerge(["rounded-md border-t border-slate-200/60 dark:border-darkmode-400", disabled && "blur-xs"])}>{children}</div>
      </div>
    </div>
  );
}

export default Box;
