import Box from 'components/Box';
import Lucide from 'components/Lucide';
import { ChargerApi, ReadChargerGroupDTO, ReadListChargerGroupDTO } from 'openapi';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { customToast } from 'utils/customToast';

const chargerApi = new ChargerApi();

export default function ChargersPage() {
  const [chargers, setChargers] = useState<ReadListChargerGroupDTO[]>([]);
  const navigate = useNavigate();
  
  useEffect(() => {
    const getChargers = async () => {
      try {
        setChargers(await chargerApi.getAllChargers());
      }
      catch (ex) {
        customToast("An error has occured", "Data was not fetched from the server", "failure");
        navigate("/dashboard");
      }
    }
    getChargers();
  }, []);
  

  return (
    <>
      <h1 className="mt-2 text-2xl font-medium intro-y mb-5">Chargers</h1>

      {chargers.map(cg => (
        <div className="p-5 mb-5 rounded-lg outline outline-dashed outline-1">
          <p>{cg.groupName}</p>
          <div className="grid grid-cols-9 gap-x-6">
            {cg.chargers!.map(c => (<Box title={c.name!} className="mt-5 col-span-2 hover:opacity-70 hover:cursor-pointer" onClick={() => navigate("/chargers/" + c.id!)}>
              <Lucide icon={'PlugZap'} className="p-14 h-full w-full"/>
            </Box>))}
            <Box title="Add New Charger" className="mt-5 col-span-2 hover:opacity-70 hover:cursor-pointer" onClick={() => navigate("/chargers/add", { state: { groupName: cg.groupName } })}>
              <Lucide icon={'PlusCircle'} className="p-14 h-full w-full"/>
            </Box>
          </div>
        </div>))}
    </>
  );
}
