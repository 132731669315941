import { createContext, useContext } from 'react';
import { twMerge } from 'tailwind-merge';

type InputGroupProps = React.PropsWithChildren & React.ComponentPropsWithoutRef<'div'>;

export const InputGroupContext = createContext(false);

function InputGroup(props: InputGroupProps) {
  return (
    <InputGroupContext.Provider value={true}>
      <div {...props} className={twMerge(['flex', props.className])}>
        {props.children}
      </div>
    </InputGroupContext.Provider>
  );
}

type TextProps = React.PropsWithChildren & React.ComponentPropsWithoutRef<'div'>;

const InputGroupText = (props: TextProps) => {
  const inputGroup = useContext(InputGroupContext);
  return (
    <div
      {...props}
      className={twMerge([
        'py-2 px-3 bg-slate-100 border shadow-sm border-slate-200 text-slate-600 dark:bg-darkmode-900/20 dark:border-darkmode-900/20 dark:text-slate-400',
        inputGroup && 'rounded-none [&:not(:first-child)]:border-l-transparent first:rounded-l last:rounded-r',
        props.className,
      ])}
    >
      {props.children}
    </div>
  );
};

InputGroup.Text = InputGroupText;

export default InputGroup;
