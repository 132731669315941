/* tslint:disable */
/* eslint-disable */
/**
 * Backend.Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateChargerDTO,
  CreatedResponseType,
  ReadChargerDTO,
  ReadListChargerGroupDTO,
  UpdateChargerDTO,
} from '../models';
import {
    CreateChargerDTOFromJSON,
    CreateChargerDTOToJSON,
    CreatedResponseTypeFromJSON,
    CreatedResponseTypeToJSON,
    ReadChargerDTOFromJSON,
    ReadChargerDTOToJSON,
    ReadListChargerGroupDTOFromJSON,
    ReadListChargerGroupDTOToJSON,
    UpdateChargerDTOFromJSON,
    UpdateChargerDTOToJSON,
} from '../models';

export interface CreateChargerRequest {
    createChargerDTO?: CreateChargerDTO;
}

export interface DeleteChargerRequest {
    id: string;
}

export interface GetChargerRequest {
    id: string;
}

export interface UpdateChargerRequest {
    id: string;
    updateChargerDTO?: UpdateChargerDTO;
}

/**
 * 
 */
export class ChargerApi extends runtime.BaseAPI {

    /**
     */
    async createChargerRaw(requestParameters: CreateChargerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreatedResponseType>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("Bearer", []);
        }

        const response = await this.request({
            path: `/chargers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateChargerDTOToJSON(requestParameters.createChargerDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreatedResponseTypeFromJSON(jsonValue));
    }

    /**
     */
    async createCharger(requestParameters: CreateChargerRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreatedResponseType> {
        const response = await this.createChargerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteChargerRaw(requestParameters: DeleteChargerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteCharger.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("Bearer", []);
        }

        const response = await this.request({
            path: `/chargers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteCharger(requestParameters: DeleteChargerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteChargerRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllChargersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ReadListChargerGroupDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("Bearer", []);
        }

        const response = await this.request({
            path: `/chargers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReadListChargerGroupDTOFromJSON));
    }

    /**
     */
    async getAllChargers(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ReadListChargerGroupDTO>> {
        const response = await this.getAllChargersRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getChargerRaw(requestParameters: GetChargerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReadChargerDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCharger.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("Bearer", []);
        }

        const response = await this.request({
            path: `/chargers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadChargerDTOFromJSON(jsonValue));
    }

    /**
     */
    async getCharger(requestParameters: GetChargerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReadChargerDTO> {
        const response = await this.getChargerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateChargerRaw(requestParameters: UpdateChargerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateCharger.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("Bearer", []);
        }

        const response = await this.request({
            path: `/chargers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateChargerDTOToJSON(requestParameters.updateChargerDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateCharger(requestParameters: UpdateChargerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateChargerRaw(requestParameters, initOverrides);
    }

}
