'use client';

import Lucide from 'components/Lucide';
import Button from 'components/Button';
import Pagination from 'components/Pagination';
import { FormSelect } from 'components/Form';
import { Table } from 'components/Table';
import IdCell from 'components/Table/IdCell';
import BooleanCell from 'components/Table/BooleanCell';
import ActionDetailsCell from 'components/Table/ActionsDetailsCell';
import Box from 'components/Box';
import { ChargingSessionApi, ReadChargingSessionDTO } from 'openapi';
import { useState, useEffect } from 'react';
import { DateTime } from 'luxon';

const chargingSessionApi = new ChargingSessionApi();

function ChargingSessionsOverview() {
  const [chargingSessions, setChargingSessions] = useState<ReadChargingSessionDTO[]>([]);
  
  useEffect(() => {
    const getChargingSessions = async () => {
      try {
        setChargingSessions(await chargingSessionApi.getAllChargingSessions());
      }
      catch (ex) {

      }
    }
    getChargingSessions();
  }, []);

  const columns = [
    {
      label: 'Charger Name',
      field: 'chargerName',
    },
    {
      label: 'Connector Id',
      field: 'connectorId',
    },
    {
      label: 'Start Time',
      field: 'startTime',
    },
    {
      label: 'End Time',
      field: 'endTime',
    },
    {
      label: 'Status',
      field: 'status',
    },
    {
      label: 'Energy Transferred',
      field: 'energyTransferred',
    },
  ];

  const data = chargingSessions?.map(s => ({
    key: s.id!,
    chargerName: s.charger!.name!,
    connectorId: s.connector!.connectorId!,
    startTime: DateTime.fromJSDate(s.startTime!).toLocaleString(DateTime.DATETIME_MED),
    endTime: s.endTime ? DateTime.fromJSDate(s.endTime!).toLocaleString(DateTime.DATETIME_MED) : "-",
    energyTransferred: s.meterStop ? s.meterStop - s.meterStart! + " kWh" : "-",
    status: s.status!,
  }));

  return (
    <>
      <Box title="Latest Charging Sessions">
        {data && data.length ? (
        <>
          <div className="mt-8 overflow-auto intro-y lg:overflow-visible sm:mt-0">
            <Table data={data} columns={columns} className="border-spacing-y-[10px] border-separate -mt-2" />
          </div>
          <div className="flex flex-wrap items-center mt-3 intro-y sm:flex-row sm:flex-nowrap">
            <Pagination className="w-full sm:w-auto sm:mr-auto">
              <Pagination.Link>
                <Lucide icon="ChevronsLeft" className="w-4 h-4" />
              </Pagination.Link>
              <Pagination.Link>
                <Lucide icon="ChevronLeft" className="w-4 h-4" />
              </Pagination.Link>
              <Pagination.Link>...</Pagination.Link>
              <Pagination.Link active>1</Pagination.Link>
              <Pagination.Link>2</Pagination.Link>
              <Pagination.Link>3</Pagination.Link>
              <Pagination.Link>...</Pagination.Link>
              <Pagination.Link>
                <Lucide icon="ChevronRight" className="w-4 h-4" />
              </Pagination.Link>
              <Pagination.Link>
                <Lucide icon="ChevronsRight" className="w-4 h-4" />
              </Pagination.Link>
            </Pagination>
            <FormSelect className="w-20 mt-3 !box sm:mt-0">
              <option>10</option>
              <option>25</option>
              <option>35</option>
              <option>50</option>
            </FormSelect>
          </div>
        </>
        ) : (<p className="mt-5">No data available</p>)}
      </Box>
    </>
  );
}

export default ChargingSessionsOverview;
