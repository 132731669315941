import ReportDonutChart from 'widgets/ReportDonutChart';
import { twMerge } from 'tailwind-merge';
import { Colors } from 'utils/colors';
import Box from 'components/Box';
import { ConnectorApi, ConnectorStatus, ReadConnectorDTO } from 'openapi';
import { useEffect, useState } from 'react';

const connectorApi = new ConnectorApi();

const colorMap = {
  disconnected: "dark",
  ready: "success",
  occupied: "primary",
  error: "danger",
} as any;

function ConnectorsOverview() {
  const [connectors, setConnectors] = useState<ReadConnectorDTO[]>([]);
  
  useEffect(() => {
    const getConnectors = async () => {
      try {
        setConnectors(await connectorApi.getAllConnectors());
      }
      catch (ex) {

      }
    }
    getConnectors();
  }, []);

  const sum = connectors.length;
  const labels = Object.keys(ConnectorStatus).map(l => l.toLowerCase());
  const chartData = labels.map(l => ({
    label: l,
    color: colorMap[l],
    value: connectors.filter(c => c.status == l).length,
  }));

  const values = chartData.map(d => d.value);
  const colors = chartData.map(d => d.color) as any[];

  if (connectors.length == 0) {
    values[1] = 1;
  }

  return (
    <>
      <div className="mt-2 intro-y relative before:content-[''] before:w-[90%] before:shadow-[0px_3px_20px_#0000000b] before:bg-slate-50 before:h-full before:mt-3 before:absolute before:rounded-md before:mx-auto before:inset-x-0 before:dark:bg-darkmode-400/70">
        <Box title="Connectors Overview">
          <div className="relative">
            <ReportDonutChart
              labels={labels}
              values={values}
              colors={colors}
              height={208}
              className="mt-3"
            />
            <div className="absolute top-0 left-0 flex flex-col items-center justify-center w-full h-full">
              <div className="text-2xl font-medium">{sum}</div>
              <div className="text-slate-500 mt-0.5">Connectors</div>
            </div>
          </div>
          <div className="mx-auto mt-5 w-52 sm:w-auto">
            {chartData.map((chartValue) => (
              <div key={chartValue.label} className="flex items-center">
                <div className={twMerge(['w-2 h-2 mr-3 rounded-full', 'bg-' + chartValue.color])}></div>
                <span className="truncate">{chartValue.label}</span>
                <span className="ml-auto font-medium">{chartValue.value}</span>
              </div>
            ))}
          </div>
        </Box>
      </div>
    </>
  );
}

export default ConnectorsOverview;
