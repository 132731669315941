import { ApplicationRouter } from './providers/applicationRouter';
import AuthProvider from 'providers/authProvider';
import { Provider } from 'react-redux';
import { AppStore } from 'store';
import EnvProvider from 'providers/envProvider';
import BackendProvider from 'providers/backendProvider';

import 'assets/css/app.css';
import StripeProvider from 'providers/stripeProvider';

function App() {
  return (
    <Provider store={AppStore}>
      <EnvProvider>
        <AuthProvider>
          <BackendProvider>
            <StripeProvider>
              <ApplicationRouter />
            </StripeProvider>
          </BackendProvider>
        </AuthProvider>
      </EnvProvider>
    </Provider>
  );
}

export default App;
