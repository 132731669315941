'use client';

import { useContext, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { EnvironmentContext } from 'providers/envProvider';

export default function LogoutPage() {
  const context = useContext(EnvironmentContext);
  const { logout } = useAuth0();

  useEffect(() => {
    logout({
      logoutParams: {
        returnTo: context.appBaseUrl + '/login',
      }
    });
  })

  return <></>;
}
