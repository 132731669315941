/* tslint:disable */
/* eslint-disable */
/**
 * Backend.Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SubscriptionModule } from './SubscriptionModule';
import {
    SubscriptionModuleFromJSON,
    SubscriptionModuleFromJSONTyped,
    SubscriptionModuleToJSON,
} from './SubscriptionModule';

/**
 * 
 * @export
 * @interface ReadSubscriptionDTO
 */
export interface ReadSubscriptionDTO {
    /**
     * 
     * @type {string}
     * @memberof ReadSubscriptionDTO
     */
    id?: string;
    /**
     * 
     * @type {SubscriptionModule}
     * @memberof ReadSubscriptionDTO
     */
    module?: SubscriptionModule;
    /**
     * 
     * @type {number}
     * @memberof ReadSubscriptionDTO
     */
    price?: number;
    /**
     * 
     * @type {Date}
     * @memberof ReadSubscriptionDTO
     */
    subscriptionTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ReadSubscriptionDTO
     */
    cancellationTime?: Date | null;
}

/**
 * Check if a given object implements the ReadSubscriptionDTO interface.
 */
export function instanceOfReadSubscriptionDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ReadSubscriptionDTOFromJSON(json: any): ReadSubscriptionDTO {
    return ReadSubscriptionDTOFromJSONTyped(json, false);
}

export function ReadSubscriptionDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReadSubscriptionDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'module': !exists(json, 'module') ? undefined : SubscriptionModuleFromJSON(json['module']),
        'price': !exists(json, 'price') ? undefined : json['price'],
        'subscriptionTime': !exists(json, 'subscriptionTime') ? undefined : (new Date(json['subscriptionTime'])),
        'cancellationTime': !exists(json, 'cancellationTime') ? undefined : (json['cancellationTime'] === null ? null : new Date(json['cancellationTime'])),
    };
}

export function ReadSubscriptionDTOToJSON(value?: ReadSubscriptionDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'module': SubscriptionModuleToJSON(value.module),
        'price': value.price,
        'subscriptionTime': value.subscriptionTime === undefined ? undefined : (value.subscriptionTime.toISOString()),
        'cancellationTime': value.cancellationTime === undefined ? undefined : (value.cancellationTime === null ? null : value.cancellationTime.toISOString()),
    };
}

