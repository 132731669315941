import Chart from 'components/Chart';
import { ChartData, ChartOptions } from 'chart.js/auto';
import { getColor } from 'utils/colors';
import { selectColorScheme } from 'store/colorSchemeSlice';
import { selectDarkMode } from 'store/darkModeSlice';
import { useAppSelector } from 'store/hooks';
import { useMemo } from 'react';

interface DemoChartPropsProps extends React.ComponentPropsWithoutRef<'canvas'> {
  width?: number | string;
  height?: number | string;
  datasetVariant?: 'actual_planned' | 'planned_optimised';
}

function DemoChart({ width = 'auto', height = 'auto', datasetVariant = 'actual_planned', className }: DemoChartPropsProps) {
  const colorScheme = useAppSelector(selectColorScheme);
  const darkMode = useAppSelector(selectDarkMode);

  const data: ChartData = useMemo(() => {
    return {
      labels: ['6 AM', '8 AM', '10 AM', '12 AM', '2 PM', '4 PM', '6 PM', '8 PM', '10 PM', '12 PM', '2 AM', '4 AM'],
      datasets: [
        {
          label: 'Power Limit',
          data: [750, 750, 750, 750, 750, 750, 750, 750, 750, 750, 750, 750],
          borderWidth: 2,
          borderColor: getColor('danger', 1),
          backgroundColor: 'transparent',
          pointBorderColor: 'transparent',
          tension: 0,
        },
        {
          label: 'Actual Power Usage',
          data: [570, 580, 240, 260, 670, 650, 690, 665, 0, 30, 380, 390],
          borderWidth: 2,
          borderColor: getColor('slate.500', 1),
          backgroundColor: 'transparent',
          pointBorderColor: 'transparent',
          tension: 0.05,
        },
        {
          label: 'Optimized Power Usage',
          data: [700, 700, 700, 100, 100, 400, 400, 400, 750, 750, 750, 700],
          borderWidth: 2,
          borderColor: getColor('primary', 1),
          backgroundColor: 'transparent',
          pointBorderColor: 'transparent',
          tension: 0,
        },
      ],
    };
  }, [colorScheme, darkMode]);

  const options: ChartOptions = useMemo(() => {
    return {
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
        },
      },
      scales: {
        x: {
          ticks: {
            font: {
              size: 12,
            },
            color: getColor('slate.500', 0.8),
          },
          grid: {
            display: false,
            drawBorder: false,
          },
        },
        y: {
          ticks: {
            font: {
              size: 12,
            },
            color: getColor('slate.500', 0.8),
            callback: function (value) {
              return value + ' kWh';
            },
          },
          grid: {
            color: darkMode ? getColor('slate.500', 0.3) : getColor('slate.300'),
            borderDash: [2, 2],
            drawBorder: false,
          },
        },
      },
    };
  }, [colorScheme, darkMode]);

  return <Chart type="line" width={width} height={height} data={data} options={options} className={className} />;
}

export default DemoChart;
