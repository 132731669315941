'use client';

import { twMerge } from 'tailwind-merge';
import { createContext } from 'react';

interface TableColumn {
  label: string;
  field: string;
  width?: string;
}

interface TableItem {
  key: string;
  [key: string]: any;
}

export interface TableProps extends React.ComponentPropsWithoutRef<'table'> {
  columns: TableColumn[];
  data: TableItem[];
  dark?: boolean;
  bordered?: boolean;
  hover?: boolean;
  striped?: boolean;
  sm?: boolean;
}

export const tableContext = createContext<{
  dark: TableProps['dark'];
  bordered: TableProps['bordered'];
  hover: TableProps['hover'];
  striped: TableProps['striped'];
  sm: TableProps['sm'];
}>({
  dark: false,
  bordered: false,
  hover: false,
  striped: false,
  sm: false,
});

export const Table = ({ columns, data, className, dark, bordered, hover, striped, sm, ...props }: TableProps) => {
  return (
    <tableContext.Provider
      value={{
        dark: dark,
        bordered: bordered,
        hover: hover,
        striped: striped,
        sm: sm,
      }}
    >
      <table
        className={twMerge(['w-full text-left', dark && 'bg-dark text-white dark:bg-black/30', className])}
        {...props}
      >
        <thead>
          <tr
            className={twMerge([
              hover && '[&:hover_td]:bg-slate-100 [&:hover_td]:dark:bg-darkmode-300 [&:hover_td]:dark:bg-opacity-50',
              striped &&
                '[&:nth-of-type(odd)_td]:bg-slate-100 [&:nth-of-type(odd)_td]:dark:bg-darkmode-300 [&:nth-of-type(odd)_td]:dark:bg-opacity-50',
              className,
            ])}
          >
            {columns.map((column) => (
              <th
                key={column.field}
                className={twMerge([
                  'border-b-0 whitespace-nowrap',
                  'font-medium px-5 py-3 border-b-2 dark:border-darkmode-300',
                  dark ? 'border-b-0' : 'border-b-0 text-slate-700',
                  dark && 'border-slate-600 dark:border-darkmode-300',
                  bordered && 'border-l border-r border-t',
                  sm && 'px-4 py-2',
                  column.width,
                  className,
                ])}
              >
                {column.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr
              className={twMerge([
                'intro-x',
                hover && '[&:hover_td]:bg-slate-100 [&:hover_td]:dark:bg-darkmode-300 [&:hover_td]:dark:bg-opacity-50',
                striped &&
                  '[&:nth-of-type(odd)_td]:bg-slate-100 [&:nth-of-type(odd)_td]:dark:bg-darkmode-300 [&:nth-of-type(odd)_td]:dark:bg-opacity-50',
                className,
              ])}
              key={item.key}
            >
              {columns.map((column) => (
                <td
                  className={twMerge([
                    'first:rounded-l-md last:rounded-r-md w-40 bg-white border-b-0 dark:bg-darkmode-600 shadow-[20px_3px_20px_#0000000b]',
                    'px-5 py-3 border-b dark:border-darkmode-300',
                    dark && 'border-slate-600 dark:border-darkmode-300',
                    bordered && 'border-l border-r border-t',
                    sm && 'px-4 py-2',
                    column.width,
                    className,
                  ])}
                  key={item.key + '-' + column.field}
                >
                  {item[column.field]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </tableContext.Provider>
  );
};
