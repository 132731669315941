import { useAuth0 } from '@auth0/auth0-react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import AlertComponent from 'components/Alert';
import Box from 'components/Box';
import Button from 'components/Button';
import { FormInline, FormInput, FormLabel, FormWrapper, InputGroup } from 'components/Form';
import Lucide from 'components/Lucide';
import { BillingInformationApi, ReadBillingInformationDTO } from 'openapi';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { customToast } from 'utils/customToast';

const billingInformationApi = new BillingInformationApi();

function BillingPage() {
  const { user } = useAuth0();
  const stripe = useStripe()!;
  const elements = useElements()!;
  const useFormOverrideCompany = useForm();
  const [billingInformation, setBillingInformation] = useState<ReadBillingInformationDTO | null>(null);
  
  const getBillingInformation = async () => {
    try {
      const billingInformationFetched = await billingInformationApi.getBillingInformation();
      setBillingInformation(billingInformationFetched);
      useFormOverrideCompany.reset(billingInformationFetched);
    }
    catch (ex)
    {
      // ignore
    }
  }

  useEffect(() => {
    getBillingInformation();
  }, []);

  const onSubmitBillingInformation = async (data: any) => {
    customToast("Processing Request", "Billing information is being stored to the server", "processing");
    const cardElement = elements.getElement("card")!;

    let tokenId = null;
    try {
      const { token } = await stripe.createToken(cardElement);
      tokenId = token?.id
    } catch (err) {
      console.log(err);
    }
    
    try {
      let result = await billingInformationApi.createOrUpdateBillingInformation({
        createOrUpdateBillingInformationDTO: {
          name: data.name,
          address: data.address,
          vatNumber: data.vatNumber,
          billingEmail: data.billingEmail,
          creditCardTokenId: tokenId,
        }
      });
      await getBillingInformation();
      cardElement?.clear();
      customToast("Billing Information successfully updated", "You can now subscribe to modules");
    }
    catch (ex) {
      customToast("An error has occured", "Data was not stored to the server", "failure");
    }
  };

  return (
    <>
      <h1 className="mt-2 text-2xl font-medium intro-y mb-5">Billing</h1>
        {billingInformation == null && <AlertComponent className="flex" variant='soft-danger'>
          <Lucide icon={'Lightbulb'} className='mr-3'/>
          <p className='h-full align-middle	'>In order to subscribe, billing information needs to be provided first.</p>
        </AlertComponent>}
        <FormWrapper className="col-span-12" onSubmit={onSubmitBillingInformation} entity={billingInformation} useFormOverride={useFormOverrideCompany}>
        <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12 lg:col-span-8 2xl:col-span-6">
              <Box title="Company Information" shadow>
                <FormInline>
                  <FormLabel className='mt-5'>Company Name</FormLabel>
                  <FormInput
                    name="name"
                    type="text"
                    className='mt-5'
                    placeholder="Legal Name"
                    onChange={() => {}}
                  />
                </FormInline>
                <FormInline>
                  <FormLabel>VAT number</FormLabel>
                  <FormInput
                    name="vatNumber"
                    type="text"
                    placeholder="VAT Number"
                    onChange={() => {}}
                  />
                </FormInline>
                <FormInline>
                  <FormLabel>Address</FormLabel>
                  <FormInput
                    name="address"
                    placeholder="Company Adress"
                    onChange={() => {}}
                  ></FormInput>
                </FormInline>
                <FormInline>
                  <FormLabel>Billing Email</FormLabel>
                  <FormInput
                    name="billingEmail"
                    placeholder="Billing Email"
                    onChange={() => {}}
                  ></FormInput>
                </FormInline>
              </Box>
            </div>
            <div className="col-span-12 lg:col-span-8 2xl:col-span-6">
              <Box title={billingInformation != null ? 'Update Credit Card' : 'Credit Card'} className="mt-5">
              {billingInformation != null && <AlertComponent className='mt-5'>
                  Credit Card (**{billingInformation.creditCardLast4Digits}) is currently in use. Fill out the input field below to store a new credit card.
                </AlertComponent>}
                <CardElement options={{
                  iconStyle: "solid",
                  hidePostalCode: true,
                }} className="mt-5"/>
              </Box>
            </div>
          </div>
          <Button type="submit" variant="primary" className="px-10 py-3 xl:mr-3 mt-5 right-0">
            Save
          </Button>
        </FormWrapper>
    </>
  );
}

export default BillingPage;
