import AlertComponent from "components/Alert";
import Box from "components/Box";
import { FormLabel, FormSwitch, FormWrapper, InputGroup } from "components/Form";
import Lucide from "components/Lucide";
import { SubscriptionApi, SubscriptionModule } from "openapi";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { handleSubscriptionChange } from "utils/subscriptions";

const subscriptionApi = new SubscriptionApi();

function FleetPage() {
  const [fleetEnabled, setFleetEnabled] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    const getActiveSubscriptions = async () => {
      try {
        const activeSubscriptions = await subscriptionApi.getActiveSubscriptions();
        const subscriptionModules = activeSubscriptions.map(x => x.module);

        if (subscriptionModules.includes(SubscriptionModule.Fleet)) {
          setFleetEnabled(true);
        }
      }
      catch (ex) {
        // ignore
      }
    }

    getActiveSubscriptions();
  }, []);

  const onChangeFleet = async () => {
    await handleSubscriptionChange(fleetEnabled, setFleetEnabled, SubscriptionModule.Fleet, navigate);
  }

  return (
    <>
      <h1 className="mt-2 text-2xl font-medium intro-y mb-5">Fleet Operations</h1>

      <Box title='Active Subscriptions' shadow>
        <FormWrapper className="mt-3">
          <InputGroup>
            <FormLabel info='100€ / charger / month'>
              Fleet Operation Management
            </FormLabel>
            <FormSwitch name='fleetEnabled' checked={fleetEnabled} onChange={onChangeFleet}/>
          </InputGroup>
        </FormWrapper>
      </Box>

      <Box title="Fleet Operation Solution" disabled={!fleetEnabled} shadow description="Problem: Fleet operations are complex, time consuming and costly with electrical vehicles. Additionally, disruptions are difficult to solve timely and cost efficient.
        Solution: Align charging operations with fleet operations to reduce operational costs and execution time. Disruptions can be handled immediately with realtime schedule updates.">
        <AlertComponent className="mt-5 flex">
          <Lucide icon={'CalendarClock'} />
          <p className='ml-3'>This feature will be available soon. You will not be billed in the meantime and informed properly before it is made available.</p>
        </AlertComponent>
      </Box>
    </>
  );
}

export default FleetPage;
