import { Elements } from "@stripe/react-stripe-js";
import { Stripe, loadStripe } from "@stripe/stripe-js";
import { useContext, useEffect } from "react";
import { EnvironmentContext } from "./envProvider";

let stripePromise: Promise<Stripe | null>;

function StripeProvider({ children }: React.PropsWithChildren) {
	var env = useContext(EnvironmentContext);
	stripePromise = loadStripe(env.stripe.publishableKey);

	return <Elements stripe={stripePromise}>
			{children}
		</Elements>;
}

export default StripeProvider;