import { createContext } from 'react';
import { twMerge } from 'tailwind-merge';

type FormInlineProps = React.PropsWithChildren & React.ComponentPropsWithoutRef<'div'>;

export const formInlineContext = createContext(false);

function FormInline({ className, ...props }: FormInlineProps) {
  return (
    <formInlineContext.Provider value={true}>
      <div
        {...props}
        className={twMerge([
          'block sm:flex items-center',
          'flex-col items-start pt-5 xl:flex-row first:mt-0 first:pt-0',
          className,
        ])}
      >
        {props.children}
      </div>
    </formInlineContext.Provider>
  );
}

export default FormInline;
