/* tslint:disable */
/* eslint-disable */
/**
 * Backend.Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ChargerStatus } from './ChargerStatus';
import {
    ChargerStatusFromJSON,
    ChargerStatusFromJSONTyped,
    ChargerStatusToJSON,
} from './ChargerStatus';

/**
 * 
 * @export
 * @interface ReadListChargerDTO
 */
export interface ReadListChargerDTO {
    /**
     * 
     * @type {string}
     * @memberof ReadListChargerDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadListChargerDTO
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReadListChargerDTO
     */
    model?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReadListChargerDTO
     */
    location?: string | null;
    /**
     * 
     * @type {ChargerStatus}
     * @memberof ReadListChargerDTO
     */
    status?: ChargerStatus;
    /**
     * 
     * @type {string}
     * @memberof ReadListChargerDTO
     */
    group?: string | null;
}

/**
 * Check if a given object implements the ReadListChargerDTO interface.
 */
export function instanceOfReadListChargerDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ReadListChargerDTOFromJSON(json: any): ReadListChargerDTO {
    return ReadListChargerDTOFromJSONTyped(json, false);
}

export function ReadListChargerDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReadListChargerDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'model': !exists(json, 'model') ? undefined : json['model'],
        'location': !exists(json, 'location') ? undefined : json['location'],
        'status': !exists(json, 'status') ? undefined : ChargerStatusFromJSON(json['status']),
        'group': !exists(json, 'group') ? undefined : json['group'],
    };
}

export function ReadListChargerDTOToJSON(value?: ReadListChargerDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'model': value.model,
        'location': value.location,
        'status': ChargerStatusToJSON(value.status),
        'group': value.group,
    };
}

