'use client';

import Box from 'components/Box';
import Button from 'components/Button';
import { FormInline, FormInput, FormLabel, FormWrapper } from 'components/Form';
import { CreateChargerDTO } from 'openapi';
import { UseFormReturn } from 'react-hook-form';

interface ChargerFormProps {
  onSubmit: (x: CreateChargerDTO) => void;
  entity?: CreateChargerDTO;
  useFormOverride?: UseFormReturn<{}, any, undefined>;
}

const ChargerForm = ({ onSubmit, entity, useFormOverride }: ChargerFormProps) => {
  return (
    <FormWrapper onSubmit={onSubmit} entity={entity} useFormOverride={useFormOverride}>
      <Box title="Charger Properties">
        <div className="mt-5">
          <FormInline>
            <FormLabel
              required={true}
              info="To easily find the charger physically"
            >
              Name
            </FormLabel>
            <FormInput name="name"/>
          </FormInline>

          <FormInline>
            <FormLabel
              required={true}
            >
              Model
            </FormLabel>
            <FormInput name="model" />
          </FormInline>

          <FormInline>
            <FormLabel
              required={true}
              info="To easily find the charger physically"
            >
              Location
            </FormLabel>
            <FormInput name="location" />
          </FormInline>

          <FormInline>
            <FormLabel
              required={true}
              info="To logically group chargers"
            >
              Group
            </FormLabel>
            <FormInput name="group" />
          </FormInline>
        </div>

        <Button type="submit" variant="primary" className="px-4 py-3 xl:mr-3 mt-5 right-0 w-full">
          Save
        </Button>
      </Box>
    </FormWrapper>
  );
};

export default ChargerForm;
