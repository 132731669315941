/* tslint:disable */
/* eslint-disable */
/**
 * Backend.Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ReadChargerGroupDTO,
} from '../models';
import {
    ReadChargerGroupDTOFromJSON,
    ReadChargerGroupDTOToJSON,
} from '../models';

/**
 * 
 */
export class ChargerGroupApi extends runtime.BaseAPI {

    /**
     */
    async getAllChargerGroupsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ReadChargerGroupDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("Bearer", []);
        }

        const response = await this.request({
            path: `/charger-groups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReadChargerGroupDTOFromJSON));
    }

    /**
     */
    async getAllChargerGroups(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ReadChargerGroupDTO>> {
        const response = await this.getAllChargerGroupsRaw(initOverrides);
        return await response.value();
    }

}
