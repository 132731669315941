import toast from "react-hot-toast";
import Lucide from "components/Lucide";

export default function CustomToast({ title, description, type = "success", toastObject }: { title: string, description: string, type?: "success" | "failure" | "processing", toastObject: any }) {
	return (
		<div
			className={`${
				toastObject.visible ? 'animate-enter' : 'animate-leave'
			} max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
		>
			<div className="flex-1 w-0 p-4">
				<div className="flex items-start">
					<div className="flex-shrink-0 pt-0.5">
					{ type == "success" ?
						<Lucide icon="CheckIcon" className="h-10 w-10 rounded-full"/> : type == "failure" ?
						<Lucide icon="X" className="h-10 w-10 rounded-full"/> :
						<Lucide icon="Loader2" className="h-10 w-10 rounded-full"/>
					}
					</div>
					<div className="ml-3 flex-1">
						<p className="text-sm font-medium text-gray-900">
							{title}
						</p>
						<p className="mt-1 text-sm text-gray-500">
							{description}
						</p>
					</div>
				</div>
			</div>
			<div className="flex border-l border-gray-200">
				<button
					onClick={() => toast.dismiss(toastObject.id)}
					className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
				>
					<Lucide icon="X" />
				</button>
			</div>
		</div>
	);
}