import ReportLineChart from 'widgets/ReportLineChart';
import { twMerge } from 'tailwind-merge';
import { ChartData, ChartOptions } from 'chart.js';
import { useEffect, useMemo, useState } from 'react';
import { selectColorScheme } from 'store/colorSchemeSlice';
import { selectDarkMode } from 'store/darkModeSlice';
import { useAppSelector } from 'store/hooks';
import { getColor } from 'utils/colors';
import { DeepPartial } from 'chart.js/dist/types/utils';
import Box from 'components/Box';
import { DateTime } from 'luxon';
import 'chartjs-adapter-luxon';
import { LoadCurveApi, ReadChartPointDto } from 'openapi';

const loadCurveApi = new LoadCurveApi();

function LoadCurveChart() {
  const colorScheme = useAppSelector(selectColorScheme);
  const darkMode = useAppSelector(selectDarkMode);

  const [loadCurve, setLoadCurve] = useState<ReadChartPointDto[] | null>(null);
  
  useEffect(() => {
    const getLoadCurveLast24h = async () => {
      try {
        const loadCurveLast24h = await loadCurveApi.getLoadCurveLast24h();
        setLoadCurve(loadCurveLast24h);
      }
      catch (ex) {

      }
    }

    getLoadCurveLast24h();
  }, []);

  const datasets: any[] = [];
  if (loadCurve == null || loadCurve.length == 0) {
    datasets.push({
      x: DateTime.now().toISO(),
      y: 0,
    });
    datasets.push({
      x: DateTime.now().minus({ days: 1 }).toISO(),
      y: 0,
    });
  }
  else {
    datasets.push(...loadCurve.map(point => ({
      x: DateTime.fromJSDate(point.time!).toISO(),
      y: point.value,
    })));
  }

  const data: ChartData = {
    datasets: [
      {
        label: 'Total Load',
        data: datasets,
        borderWidth: 2,
        borderColor: getColor('primary', 1),
        backgroundColor: getColor('primary', 1),
        pointBorderColor: getColor('primary', 1),
        stepped: true,
      },
    ],
  };

  const options: DeepPartial<ChartOptions> = {
    scales: {
      x: {
        type: 'time',
        time: {
          tooltipFormat: 'T',
        },
        title: {
          display: true,
          text: "Time"
        },
      },
      y: {
        min: 0,
        ticks: {
          callback: function (value, index, ticks) {
            return index % 2 ?'' : value + ' kW';
          },
        },

      },
    },
  };

  return (
    <>
      <div className="intro-y mt-2 relative before:content-[''] before:w-[90%] before:shadow-[0px_3px_20px_#0000000b] before:bg-slate-50 before:h-full before:mt-3 before:absolute before:rounded-md before:mx-auto before:inset-x-0 before:dark:bg-darkmode-400/70">
        <Box title="Load Curve">
          {false && <div className="flex flex-col md:flex-row md:items-center">
            <div className="flex mt-5">
              <div className="">
                <div className="text-lg font-medium text-primary dark:text-slate-300 xl:text-xl"><strong>200</strong> kWh</div>
                <div className="mt-0.5 text-slate-500">Last 24h</div>
              </div>
              <div className="w-px h-12 mx-4 border border-r border-dashed border-slate-200 dark:border-darkmode-300 xl:mx-5"></div>
              <div>
                <div className="text-lg font-medium text-slate-500 xl:text-xl"><strong>5000</strong> kWh</div>
                <div className="mt-0.5 text-slate-500">Last Month</div>
              </div>
            </div>
          </div>}
          <div>
            <ReportLineChart height={275} className="mt-2 -mb-2" data={data} options={options}/>
          </div>
        </Box>
      </div>
    </>
  );
}

export default LoadCurveChart;
