import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import darkModeReducer from './darkModeSlice';
import colorSchemeReducer from './colorSchemeSlice';
import sideMenuReducer from './sideMenuSlice';

export const AppStore = configureStore({
  reducer: {
    darkMode: darkModeReducer,
    colorScheme: colorSchemeReducer,
    sideMenu: sideMenuReducer,
  },
});

export type AppDispatch = typeof AppStore.dispatch;
export type RootState = ReturnType<typeof AppStore.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
