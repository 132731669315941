import camelCase from 'lodash/camelCase';
import isObjectLike from 'lodash/isObjectLike';
import size from 'lodash/size';
import startCase from 'lodash/startCase';

export const getFieldName = (field: string) => {
  return startCase(String(field.split('.').join(' ')));
};

export const getFieldId = (field: string) => {
  return camelCase(field + '_input');
};

export const cleanFormData = (obj: any) => {
  return removeEmptyValues(obj);

  function isFalsy(val: any) {
    return !isObjectLike(val) ? val === null : size(val) <= 0;
  }

  function removeEmptyValues(data: any) {
    if (isObjectLike(data)) {
      const result = Object.entries(data).reduce(
        (res: any, [key, value]) => {
          if (Array.isArray(value)) {
            res[key] = value.filter((i) => i !== false);
          } else {
            const newValue = removeEmptyValues(value);

            if (!isFalsy(newValue)) {
              res[key] = newValue;
            }
          }

          return res;
        },
        Array.isArray(data) ? [] : {}
      );

      return isFalsy(result) ? undefined : result;
    }

    return data;
  }
};
