/* tslint:disable */
/* eslint-disable */
/**
 * Backend.Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReadAccessRuleDto
 */
export interface ReadAccessRuleDto {
    /**
     * 
     * @type {string}
     * @memberof ReadAccessRuleDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadAccessRuleDto
     */
    idTag?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReadAccessRuleDto
     */
    name?: string | null;
}

/**
 * Check if a given object implements the ReadAccessRuleDto interface.
 */
export function instanceOfReadAccessRuleDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ReadAccessRuleDtoFromJSON(json: any): ReadAccessRuleDto {
    return ReadAccessRuleDtoFromJSONTyped(json, false);
}

export function ReadAccessRuleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReadAccessRuleDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'idTag': !exists(json, 'idTag') ? undefined : json['idTag'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function ReadAccessRuleDtoToJSON(value?: ReadAccessRuleDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'idTag': value.idTag,
        'name': value.name,
    };
}

