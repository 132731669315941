/* tslint:disable */
/* eslint-disable */
/**
 * Backend.Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ReadChargingSessionDTO,
} from '../models';
import {
    ReadChargingSessionDTOFromJSON,
    ReadChargingSessionDTOToJSON,
} from '../models';

export interface GetChargingSessionRequest {
    id: string;
}

/**
 * 
 */
export class ChargingSessionApi extends runtime.BaseAPI {

    /**
     */
    async getAllChargingSessionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ReadChargingSessionDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("Bearer", []);
        }

        const response = await this.request({
            path: `/charging-sessions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReadChargingSessionDTOFromJSON));
    }

    /**
     */
    async getAllChargingSessions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ReadChargingSessionDTO>> {
        const response = await this.getAllChargingSessionsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getChargingSessionRaw(requestParameters: GetChargingSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReadChargingSessionDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getChargingSession.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("Bearer", []);
        }

        const response = await this.request({
            path: `/charging-sessions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadChargingSessionDTOFromJSON(jsonValue));
    }

    /**
     */
    async getChargingSession(requestParameters: GetChargingSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReadChargingSessionDTO> {
        const response = await this.getChargingSessionRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
