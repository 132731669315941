/* tslint:disable */
/* eslint-disable */
/**
 * Backend.Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReadPowerLimitDto
 */
export interface ReadPowerLimitDto {
    /**
     * 
     * @type {string}
     * @memberof ReadPowerLimitDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadPowerLimitDto
     */
    group?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReadPowerLimitDto
     */
    powerLimit?: number;
}

/**
 * Check if a given object implements the ReadPowerLimitDto interface.
 */
export function instanceOfReadPowerLimitDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ReadPowerLimitDtoFromJSON(json: any): ReadPowerLimitDto {
    return ReadPowerLimitDtoFromJSONTyped(json, false);
}

export function ReadPowerLimitDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReadPowerLimitDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'group': !exists(json, 'group') ? undefined : json['group'],
        'powerLimit': !exists(json, 'powerLimit') ? undefined : json['powerLimit'],
    };
}

export function ReadPowerLimitDtoToJSON(value?: ReadPowerLimitDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'group': value.group,
        'powerLimit': value.powerLimit,
    };
}

