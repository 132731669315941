import { createRef, useEffect, useRef } from 'react';
import ChartJs, { ChartConfiguration } from 'chart.js/auto';

export interface ChartElement extends HTMLCanvasElement {
  instance: ChartJs;
}

export interface ChartProps extends React.ComponentPropsWithoutRef<'canvas'>, ChartConfiguration {
  width: number | string;
  height: number | string;
  getRef?: (el: ChartElement | null) => void;
}

const init = (el: ChartElement, props: ChartProps) => {
  const canvas = el?.getContext('2d');
  if (canvas) {
    el.instance = new ChartJs(canvas, {
      type: props.type,
      data: props.data,
      options: props.options,
    });
  }
};

function Chart(props: ChartProps) {
  let {
    type = 'line',
    data = {},
    options = {},
    width = 'auto',
    height = 'auto',
    getRef = () => {},
    ...computedProps
  } = props;
  if (!getRef) {
    getRef = () => {};
  }
  if (!data) {
    data = {};
  }

  const initialRender = useRef(true);
  const chartRef = createRef<ChartElement>();

  useEffect(() => {
    if (initialRender.current) {
      getRef(chartRef.current);
      chartRef.current && init(chartRef.current, props);
      initialRender.current = false;
    } else {
      if (chartRef.current) {
        chartRef.current.instance.data = props.data;
        if (props.options) {
          chartRef.current.instance.options = props.options;
        }
        chartRef.current.instance.update();
      }
    }
  }, [data, props.options]);

  return (
    <div
      style={{
        width: `${width}px`,
        height: `${height}px`,
      }}
    >
      <canvas {...computedProps} ref={chartRef}></canvas>
    </div>
  );
}

export default Chart;
