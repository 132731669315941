import { ErrorCode, SubscriptionApi, SubscriptionModule } from "openapi";
import { customToast } from "./customToast";
import { Dispatch, SetStateAction } from "react";
import { NavigateFunction } from "react-router-dom";

const subscriptionApi = new SubscriptionApi();

export async function handleSubscriptionChange(currentSubscriptionStatus: boolean, setSubscriptionStatus: Dispatch<SetStateAction<boolean>>, module: SubscriptionModule, navigate: NavigateFunction) {	
	const newSubscriptionStatus = !currentSubscriptionStatus;
	try
	{
		const moduleDescription = module.charAt(0).toUpperCase() + module.slice(1);
		if (newSubscriptionStatus) {
			await subscriptionApi.subscribe({
				module: module,
			});
			customToast("Successfully subscribed", `You can now use the ${moduleDescription} module`);
		}
		else {
			await subscriptionApi.unsubscribe({
				module: module,
			});
			customToast("Successfully unsubscribed", `You will no longer be billed for the ${moduleDescription} module`);
		}

		setSubscriptionStatus(newSubscriptionStatus);
	}
	catch (ex: any)
	{
		try {
			const response = ex.response as Response;
			const { errorCode } = await response.json() as { errorCode: ErrorCode };

			if (errorCode == ErrorCode.BillingInformationNotProvided) {
				navigate("/billing");
				customToast("Subscription failed", "Set up billing information first", "failure");
			}
			else if (errorCode == ErrorCode.SubscriptionAlreadyActive) {
				customToast("Subscription is already active", "Refresh the page and contact our support if this problem persists", "failure");
			}
			else if (errorCode == ErrorCode.SubscriptionNotActive) {
				customToast("Subscription is not active", "Refresh the page and contact our support if this problem persists", "failure");
			}
		}
		catch {
			customToast("Error", "A error has occured", "failure");
		}
	}
}