/* tslint:disable */
/* eslint-disable */
/**
 * Backend.Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreatedResponseType,
  ErrorDto,
  ReadSubscriptionDTO,
  SubscriptionModule,
} from '../models';
import {
    CreatedResponseTypeFromJSON,
    CreatedResponseTypeToJSON,
    ErrorDtoFromJSON,
    ErrorDtoToJSON,
    ReadSubscriptionDTOFromJSON,
    ReadSubscriptionDTOToJSON,
    SubscriptionModuleFromJSON,
    SubscriptionModuleToJSON,
} from '../models';

export interface SubscribeRequest {
    module: SubscriptionModule;
}

export interface UnsubscribeRequest {
    module: SubscriptionModule;
}

/**
 * 
 */
export class SubscriptionApi extends runtime.BaseAPI {

    /**
     */
    async getActiveSubscriptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ReadSubscriptionDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("Bearer", []);
        }

        const response = await this.request({
            path: `/subscriptions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReadSubscriptionDTOFromJSON));
    }

    /**
     */
    async getActiveSubscriptions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ReadSubscriptionDTO>> {
        const response = await this.getActiveSubscriptionsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async subscribeRaw(requestParameters: SubscribeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreatedResponseType>> {
        if (requestParameters.module === null || requestParameters.module === undefined) {
            throw new runtime.RequiredError('module','Required parameter requestParameters.module was null or undefined when calling subscribe.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("Bearer", []);
        }

        const response = await this.request({
            path: `/subscriptions/{module}`.replace(`{${"module"}}`, encodeURIComponent(String(requestParameters.module))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreatedResponseTypeFromJSON(jsonValue));
    }

    /**
     */
    async subscribe(requestParameters: SubscribeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreatedResponseType> {
        const response = await this.subscribeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async unsubscribeRaw(requestParameters: UnsubscribeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.module === null || requestParameters.module === undefined) {
            throw new runtime.RequiredError('module','Required parameter requestParameters.module was null or undefined when calling unsubscribe.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("Bearer", []);
        }

        const response = await this.request({
            path: `/subscriptions/{module}`.replace(`{${"module"}}`, encodeURIComponent(String(requestParameters.module))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async unsubscribe(requestParameters: UnsubscribeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.unsubscribeRaw(requestParameters, initOverrides);
    }

}
