type FormLabelProps = React.PropsWithChildren & React.ComponentPropsWithoutRef<'div'>;

function FormInputWrapper({ children, ...props }: FormLabelProps) {
  return (
    <div className="flex-1 w-full mt-3 xl:mt-0" {...props}>
      {children}
    </div>
  );
}

export default FormInputWrapper;
