import { twMerge } from 'tailwind-merge';
import { useContext } from 'react';
import { formInlineContext } from 'components/Form/FormInline';
import { InputGroupContext } from 'components/Form/InputGroup';
import { useFormContext } from 'react-hook-form';

interface FormSwitchProps extends React.ComponentPropsWithoutRef<'input'> {
  name: string;
  formInputSize?: 'sm' | 'lg';
}

const FormSwitch: React.FC<FormSwitchProps> = (props) => {
  const formInline = useContext(formInlineContext);
  const inputGroup = useContext(InputGroupContext);
  const methods = useFormContext();
  const { name, value, onChange, formInputSize, className, ...computedProps } = props;

  return (
    <>
      <input
        {...computedProps}
        {...methods.register(name)}
        value={value}
        onChange={onChange}
        type="checkbox"
        className={twMerge([
          className,
          'shadow-sm border-slate-200 cursor-pointer rounded focus:ring-4 focus:ring-offset-0 focus:ring-primary focus:ring-opacity-20 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50',

          // Default
          'w-[42px] h-[28px] p-px rounded-full relative mt-2',
          'before:w-[24px] before:h-[24px] before:shadow-[1px_1px_3px_rgba(0,0,0,0.25)] before:transition-[margin-left] before:duration-200 before:ease-in-out before:absolute before:inset-y-0 before:my-auto before:rounded-full before:dark:bg-darkmode-600',

          // On checked
          'checked:bg-primary checked:border-primary checked:bg-none',
          'before:checked:ml-[14px] before:checked:bg-white',
        ])}
      />
    </>
  );
};

export default FormSwitch;
