import Button from 'components/Button';
import Lucide, { Icon } from '../Lucide';
import { MouseEvent } from 'react';
import { NavLink } from 'react-router-dom';

export default function ActionCell({ title, icon, action }: { title: string; icon: Icon, action: any }) {
  return (
    <div className="flex items-center">
      <Button variant="outline-primary" className="flex items-center" onClick={action}>
        <Lucide icon={icon} className="w-4 h-4 mr-2" /> {title}
      </Button>
    </div>
  );
}
