import Box from "components/Box";
import { FormLabel, FormSwitch, FormWrapper, InputGroup } from "components/Form";
import { SubscriptionApi, SubscriptionModule } from "openapi";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { handleSubscriptionChange } from "utils/subscriptions";
import AccessManagementSection from "./components/AccessManagementSection";

const subscriptionApi = new SubscriptionApi();

function AccessPage() {
  const [accessEnabled, setAccessEnabled] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    const getActiveSubscriptions = async () => {
      try {
        const activeSubscriptions = await subscriptionApi.getActiveSubscriptions();
        const subscriptionModules = activeSubscriptions.map(x => x.module);

        if (subscriptionModules.includes(SubscriptionModule.Access)) {
          setAccessEnabled(true);
        }
      }
      catch (ex) {
        // ignore
      }
    }

    getActiveSubscriptions();
  }, []);

  const onChangeAccess = async () => {
    await handleSubscriptionChange(accessEnabled, setAccessEnabled, SubscriptionModule.Access, navigate);
  }

  return (
    <>
      <h1 className="mt-2 text-2xl font-medium intro-y mb-5">Access</h1>

      <Box title='Active Subscriptions' shadow>
        <FormWrapper className="mt-3">
          <InputGroup>
            <FormLabel info='10€ / charger / month'>
              Access Management
            </FormLabel>
            <FormSwitch name='emissionsEnabled' checked={accessEnabled} onChange={onChangeAccess}/>
          </InputGroup>
        </FormWrapper>
      </Box>

      <AccessManagementSection disabled={!accessEnabled}/>
    </>
  );
}

export default AccessPage;
