/* tslint:disable */
/* eslint-disable */
/**
 * Backend.Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ConnectorStatus = {
    Disconnected: 'disconnected',
    Ready: 'ready',
    Occupied: 'occupied',
    Error: 'error'
} as const;
export type ConnectorStatus = typeof ConnectorStatus[keyof typeof ConnectorStatus];


export function ConnectorStatusFromJSON(json: any): ConnectorStatus {
    return ConnectorStatusFromJSONTyped(json, false);
}

export function ConnectorStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectorStatus {
    return json as ConnectorStatus;
}

export function ConnectorStatusToJSON(value?: ConnectorStatus | null): any {
    return value as any;
}

