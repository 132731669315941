import * as lucideIcons from "lucide-react";
import clsx from "clsx";

export const { ...icons } = lucideIcons;

export type Icon = keyof typeof icons;

interface LucideProps extends React.ComponentPropsWithoutRef<"svg"> {
  icon: Icon;
  title?: string;
}

function Lucide(props: LucideProps) {
  const { icon, className, ...computedProps } = props;
  const LucideIcon = lucideIcons[props.icon];

  // @ts-ignore
  return <LucideIcon
      {...computedProps}
      className={clsx(["stroke-1.5", props.className])}
    />;
}

export default Lucide;
