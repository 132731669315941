import React from 'react';
import envLoader from '@beam-australia/react-env';

interface ChargdEnvironmentVariables {
  auth0: {
    domain: string,
    audience: string,
    clientId: string,
  };
  stripe: {
    publishableKey: string;
  };
  appBaseUrl: string;
  backendBaseUrl: string;
  ocppEndpoint: string;
}

export const EnvironmentContext = React.createContext<ChargdEnvironmentVariables>({
  auth0: {
    domain: '',
    audience: '',
    clientId: '',
  },
  stripe: {
    publishableKey: '',
  },
  appBaseUrl: '',
  backendBaseUrl: '',
  ocppEndpoint: '',
});

function EnvProvider({ children }: React.PropsWithChildren) {
  const env: ChargdEnvironmentVariables = {
    auth0: {
      domain: envLoader('AUTH0_DOMAIN'),
      audience: envLoader('AUTH0_AUDIENCE'),
      clientId: envLoader('AUTH0_CLIENT_ID'),
    },
    stripe: {
      publishableKey: envLoader('STRIPE_PUBLISHABLE_KEY'),
    },
    appBaseUrl: envLoader('APP_BASE_URL'),
    backendBaseUrl: envLoader('BACKEND_BASE_URL'),
    ocppEndpoint: envLoader('OCPP_ENDPOINT'),
  }

  return ( 
    <EnvironmentContext.Provider value={env}>
      {children}
    </EnvironmentContext.Provider>
  );
}

export default EnvProvider;
