import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { icons } from 'components/Lucide';

export interface Menu {
  icon: keyof typeof icons;
  title: string;
  pathname?: string;
  subMenu?: Menu[];
  ignore?: boolean;
  new?: boolean;
}

export interface SideMenuState {
  menu: Array<Menu | 'divider'>;
}

const initialState: SideMenuState = {
  menu: [
    {
      title: 'Dashboard',
      pathname: '/dashboard',
      icon: 'LayoutDashboard',
    },
    {
      title: 'Optimization',
      pathname: '/optimization',
      icon: 'Calendar',
    },
    {
      title: 'Fleet',
      pathname: '/fleet',
      icon: 'Car',
      new: true,
    },
    {
      title: 'Chargers',
      pathname: '/chargers',
      icon: 'PlugZap',
    },
    {
      title: 'Notifications',
      pathname: '/notifications',
      icon: "BellIcon",
    },
    {
      title: 'Emissions',
      pathname: '/emissions',
      icon: 'Cloudy',
      new: true,
    },
    {
      title: 'Forwarding',
      pathname: '/forwarding',
      icon: 'MoveHorizontal',
      new: true,
    },
    {
      title: 'Access',
      pathname: '/access',
      icon: 'User2',
    },
    {
      title: 'Billing',
      pathname: '/billing',
      icon: 'CreditCard',
    },
    {
      title: 'Logout',
      pathname: '/logout',
      icon: 'LogOut',
    },
  ],
};

export const sideMenuSlice = createSlice({
  name: 'sideMenu',
  initialState,
  reducers: {},
});

export const selectSideMenu = (state: RootState) => state.sideMenu.menu;

export default sideMenuSlice.reducer;
