/* tslint:disable */
/* eslint-disable */
/**
 * Backend.Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateOrUpdateNotificationSettingsDTO,
  CreatedResponseType,
  ReadNotificationSettingsDTO,
} from '../models';
import {
    CreateOrUpdateNotificationSettingsDTOFromJSON,
    CreateOrUpdateNotificationSettingsDTOToJSON,
    CreatedResponseTypeFromJSON,
    CreatedResponseTypeToJSON,
    ReadNotificationSettingsDTOFromJSON,
    ReadNotificationSettingsDTOToJSON,
} from '../models';

export interface CreateOrUpdateNotificationSettingsRequest {
    createOrUpdateNotificationSettingsDTO?: CreateOrUpdateNotificationSettingsDTO;
}

/**
 * 
 */
export class NotificationSettingsApi extends runtime.BaseAPI {

    /**
     */
    async createOrUpdateNotificationSettingsRaw(requestParameters: CreateOrUpdateNotificationSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("Bearer", []);
        }

        const response = await this.request({
            path: `/notification-settings`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateOrUpdateNotificationSettingsDTOToJSON(requestParameters.createOrUpdateNotificationSettingsDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createOrUpdateNotificationSettings(requestParameters: CreateOrUpdateNotificationSettingsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createOrUpdateNotificationSettingsRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteNotificationSettingsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("Bearer", []);
        }

        const response = await this.request({
            path: `/notification-settings`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteNotificationSettings(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteNotificationSettingsRaw(initOverrides);
    }

    /**
     */
    async getNotificationSettingsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReadNotificationSettingsDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("Bearer", []);
        }

        const response = await this.request({
            path: `/notification-settings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadNotificationSettingsDTOFromJSON(jsonValue));
    }

    /**
     */
    async getNotificationSettings(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReadNotificationSettingsDTO> {
        const response = await this.getNotificationSettingsRaw(initOverrides);
        return await response.value();
    }

}
