/* tslint:disable */
/* eslint-disable */
/**
 * Backend.Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ConnectorStatus } from './ConnectorStatus';
import {
    ConnectorStatusFromJSON,
    ConnectorStatusFromJSONTyped,
    ConnectorStatusToJSON,
} from './ConnectorStatus';

/**
 * 
 * @export
 * @interface ReadConnectorDTO
 */
export interface ReadConnectorDTO {
    /**
     * 
     * @type {string}
     * @memberof ReadConnectorDTO
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof ReadConnectorDTO
     */
    connectorId?: number;
    /**
     * 
     * @type {ConnectorStatus}
     * @memberof ReadConnectorDTO
     */
    status?: ConnectorStatus;
    /**
     * 
     * @type {number}
     * @memberof ReadConnectorDTO
     */
    currentPower?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReadConnectorDTO
     */
    initialEnergy?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReadConnectorDTO
     */
    currentEnergy?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReadConnectorDTO
     */
    currentStateOfCharge?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReadConnectorDTO
     */
    currentTemperature?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReadConnectorDTO
     */
    currentPowerFactor?: number | null;
}

/**
 * Check if a given object implements the ReadConnectorDTO interface.
 */
export function instanceOfReadConnectorDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ReadConnectorDTOFromJSON(json: any): ReadConnectorDTO {
    return ReadConnectorDTOFromJSONTyped(json, false);
}

export function ReadConnectorDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReadConnectorDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'connectorId': !exists(json, 'connectorId') ? undefined : json['connectorId'],
        'status': !exists(json, 'status') ? undefined : ConnectorStatusFromJSON(json['status']),
        'currentPower': !exists(json, 'currentPower') ? undefined : json['currentPower'],
        'initialEnergy': !exists(json, 'initialEnergy') ? undefined : json['initialEnergy'],
        'currentEnergy': !exists(json, 'currentEnergy') ? undefined : json['currentEnergy'],
        'currentStateOfCharge': !exists(json, 'currentStateOfCharge') ? undefined : json['currentStateOfCharge'],
        'currentTemperature': !exists(json, 'currentTemperature') ? undefined : json['currentTemperature'],
        'currentPowerFactor': !exists(json, 'currentPowerFactor') ? undefined : json['currentPowerFactor'],
    };
}

export function ReadConnectorDTOToJSON(value?: ReadConnectorDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'connectorId': value.connectorId,
        'status': ConnectorStatusToJSON(value.status),
        'currentPower': value.currentPower,
        'initialEnergy': value.initialEnergy,
        'currentEnergy': value.currentEnergy,
        'currentStateOfCharge': value.currentStateOfCharge,
        'currentTemperature': value.currentTemperature,
        'currentPowerFactor': value.currentPowerFactor,
    };
}

