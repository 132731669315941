import { Auth0Provider, useAuth0, User } from '@auth0/auth0-react';
import React, { useContext } from 'react';
import { EnvironmentContext } from './envProvider';
import LoadingIcon from 'components/LoadingIcon';

function AuthImplementationProvider({ children }: React.PropsWithChildren) {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-full">
        <span className='h-1/4 w-1/4'>
          <LoadingIcon icon="puff"/>
        </span>
      </div>
    );
  }

  return (
    <>
      {children}
    </>
  )
}

function AuthProvider({ children }: React.PropsWithChildren) {
  const env = useContext(EnvironmentContext);
  return (
    <Auth0Provider
      domain={env.auth0.domain}
      clientId={env.auth0.clientId}
      authorizationParams={{
        redirect_uri: env.appBaseUrl + '/dashboard',
        audience: env.auth0.audience,
      }}
    >
      <AuthImplementationProvider>
        {children}
      </AuthImplementationProvider>
    </Auth0Provider>
  );
}

export default AuthProvider;
