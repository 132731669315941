import { ChargerApi, CreateChargerDTO } from "openapi";
import ChargerForm from "./components/ChargerForm";
import toast from 'react-hot-toast';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomToast from "components/CustomToast";
import { customToast } from "utils/customToast";

const chargerApi = new ChargerApi();

function ChargerAddPage() {
  const navigate = useNavigate();
  let { groupName } = useLocation().state;

  const onSubmit = async (data: CreateChargerDTO) => {
    try {
      let result = await chargerApi.createCharger({
        createChargerDTO: data,
      });
      if (result.id) {
        customToast("Charger Successfully created", "ospele ospele");
        navigate("/chargers/" + result.id);
      }
    }
    catch (ex)
    {

    }
  }

  const entity = { group: groupName } as CreateChargerDTO;

  return (
    <>
      <div className="flex items-center mt-2 intro-y">
        <h2 className="mr-auto text-lg font-medium">Add Charger</h2>
      </div>

      <div className="grid grid-cols-11 mt-5 pb-20 gap-x-6">
        <div className="col-span-11 xl:col-span-7 intro-y">
          <ChargerForm onSubmit={onSubmit} entity={entity}/>
        </div>
      </div>
    </>
  );
}

export default ChargerAddPage;
