import { Outlet } from 'react-router-dom';
import AuthenticationProtected from './ProtectedRoute';
import Login from 'pages/login/LoginPage';
import Dashboard from 'pages/dashboard/DashboardPage';
import { PropsWithChildren } from 'react';
import MobileMenu from 'components/MobileMenu';
import SideMenu from 'components/SideMenu';

export function MenuProvider() {
	return (
    <>
      <MobileMenu />
      <div className="flex mt-[4.7rem] md:mt-0">
        <SideMenu />
        {/* BEGIN: Content */}
        <div className="rounded-[30px] pt-4 min-w-0 min-h-screen flex-1 pb-10 bg-slate-100 dark:bg-darkmode-700 px-4 md:px-[22px] max-w-full md:max-w-auto before:content-[''] before:w-full before:h-px before:block">
          <Outlet />
        </div>
        {/* END: Content */}
      </div>
    </>
	);
}