/* tslint:disable */
/* eslint-disable */
/**
 * Backend.Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateAccessRuleDto,
  CreatedResponseType,
  ReadAccessRuleDto,
} from '../models';
import {
    CreateAccessRuleDtoFromJSON,
    CreateAccessRuleDtoToJSON,
    CreatedResponseTypeFromJSON,
    CreatedResponseTypeToJSON,
    ReadAccessRuleDtoFromJSON,
    ReadAccessRuleDtoToJSON,
} from '../models';

export interface CreateAccessRuleRequest {
    createAccessRuleDto?: CreateAccessRuleDto;
}

export interface DeleteAccessRuleRequest {
    id: string;
}

/**
 * 
 */
export class AccessApi extends runtime.BaseAPI {

    /**
     */
    async createAccessRuleRaw(requestParameters: CreateAccessRuleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreatedResponseType>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("Bearer", []);
        }

        const response = await this.request({
            path: `/access`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateAccessRuleDtoToJSON(requestParameters.createAccessRuleDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreatedResponseTypeFromJSON(jsonValue));
    }

    /**
     */
    async createAccessRule(requestParameters: CreateAccessRuleRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreatedResponseType> {
        const response = await this.createAccessRuleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteAccessRuleRaw(requestParameters: DeleteAccessRuleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteAccessRule.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("Bearer", []);
        }

        const response = await this.request({
            path: `/access/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteAccessRule(requestParameters: DeleteAccessRuleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteAccessRuleRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllAccessRulesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ReadAccessRuleDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("Bearer", []);
        }

        const response = await this.request({
            path: `/access`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReadAccessRuleDtoFromJSON));
    }

    /**
     */
    async getAllAccessRules(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ReadAccessRuleDto>> {
        const response = await this.getAllAccessRulesRaw(initOverrides);
        return await response.value();
    }

}
