'use client';

import Box from 'components/Box';
import Button from 'components/Button';
import { FormInline, FormInput, FormLabel, FormSwitch, FormWrapper } from 'components/Form';
import { CreateOrUpdateNotificationSettingsDTO, CreatedResponseType, NotificationSettingsApi, ReadNotificationSettingsDTO } from 'openapi';
import { useEffect, useState } from 'react';
import { UseFormReturn, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { customToast } from 'utils/customToast';

const notificationSettingsApi = new NotificationSettingsApi();

const NotificationSettingsForm = () => {
  const useFormOverride = useForm();
  const [notificationSettings, setNotificationSettings] = useState<ReadNotificationSettingsDTO | null>(null);

  useEffect(() => {
    const getNotificationSettings = async () => {
      try {
        const notificationSettingsFetched = await notificationSettingsApi.getNotificationSettings();
        setNotificationSettings(notificationSettingsFetched);
        useFormOverride.reset(notificationSettingsFetched);
      }
      catch (ex) {
        // ignore
      }
    }

    getNotificationSettings();
  }, []);

  const onSubmit = async (data: CreateOrUpdateNotificationSettingsDTO) => {
    try {
      let result = await notificationSettingsApi.createOrUpdateNotificationSettings({
        createOrUpdateNotificationSettingsDTO: data,
      });
      // TODO retreive the newly generated ID
      setNotificationSettings(data);
      customToast("Notification Settings successfully updated", "Data stored to the server");
    }
    catch (ex) {
      customToast("An error has occured", "Data was not stored to the server", "failure");
    }
  };

  const onDelete = async (event: any) => {
    if (notificationSettings == null) {
      return;
    }

    try {
      let result = await notificationSettingsApi.deleteNotificationSettings();
      setNotificationSettings(null);
      useFormOverride.reset({ email: null });
      customToast("Notification Settings successfully updated", "Data stored to the server");

    }
    catch (ex) {
      customToast("An error has occured", "Data was not stored to the server", "failure");
    }
  };

  return (
    <Box title="Notification Settings">
      <FormWrapper onSubmit={onSubmit} entity={notificationSettings} useFormOverride={useFormOverride}>
        <div className="mt-5">
          <FormInline>
            <FormLabel>
              E-Mail
            </FormLabel>
            <FormInput name="email" type="email" />
          </FormInline>
        </div>

        <Button type="submit" variant="primary" className="px-4 py-3 xl:mr-3 mt-5 right-0 w-full">
          Save
        </Button>
        <Button type="button" variant="danger" className="px-4 py-3 xl:mr-3 mt-5 right-0 w-full" onClick={onDelete} disabled={!notificationSettings}>
          Disable Notifications
        </Button>
      </FormWrapper>
    </Box>
  );
};

export default NotificationSettingsForm;