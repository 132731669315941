import { useContext } from 'react';
import { formInlineContext } from '../FormInline';
import { twMerge } from 'tailwind-merge';

type FormLabelProps = React.PropsWithChildren &
  React.ComponentPropsWithoutRef<'label'> & {
    required?: boolean;
    info?: string;
  };

function FormLabel({ children, className, required = false, info, ...props }: FormLabelProps) {
  const formInline = useContext(formInlineContext);
  return (
    <label
      {...props}
      className={twMerge([
        'inline-block mb-2',
        'xl:w-40 xl:!mr-10',
        formInline && 'mb-3 sm:mr-5 sm:text-right',
        className,
      ])}
    >
      <div className="text-left">
        <div className="flex items-center mt-3">
          <div className="font-medium">{children}</div>
          {required && (
            <div className="ml-2 px-2 py-0.5 bg-slate-200 text-slate-600 dark:bg-darkmode-300 dark:text-slate-400 text-xs rounded-md">
              Required
            </div>
          )}
        </div>
        {info && <div className="mt-3 text-xs leading-relaxed text-slate-500">{info}</div>}
      </div>
    </label>
  );
}

export default FormLabel;
