import { ChartData, ChartOptions } from "chart.js";
import AlertComponent from "components/Alert";
import Box from "components/Box";
import Chart from "components/Chart";
import { FormLabel, FormSwitch, FormWrapper, InputGroup } from "components/Form";
import Lucide from "components/Lucide";
import { SubscriptionApi, SubscriptionModule } from "openapi";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { handleSubscriptionChange } from "utils/subscriptions";
import { getColor } from 'utils/colors';

const subscriptionApi = new SubscriptionApi();

function EmissionsPage() {
  const [emissionsEnabled, setEmissionsEnabled] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    const getActiveSubscriptions = async () => {
      try {
        const activeSubscriptions = await subscriptionApi.getActiveSubscriptions();
        const subscriptionModules = activeSubscriptions.map(x => x.module);

        if (subscriptionModules.includes(SubscriptionModule.Emissions)) {
          setEmissionsEnabled(true);
        }
      }
      catch (ex) {
        // ignore
      }
    }

    getActiveSubscriptions();
  }, []);

  const onChangeEmissions = async () => {
    await handleSubscriptionChange(emissionsEnabled, setEmissionsEnabled, SubscriptionModule.Emissions, navigate);
  }

  const data: ChartData = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [
      {
        label: 'Energy Curve',
        data: [11, 42, 66, 71, 78, 84, 102, 123, 160, 179, 195, 203],
        borderWidth: 2,
        borderColor: getColor('primary', 1),
        backgroundColor: 'transparent',
        pointBorderColor: 'transparent',
        tension: 0,
      },
    ],
  };

  const options: ChartOptions = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          font: {
            size: 12,
          },
          color: getColor('slate.500', 0.8),
        },
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          font: {
            size: 12,
          },
          color: getColor('slate.500', 0.8),
          callback: function (value) {
            return value + ' kWh';
          },
        },
        grid: {
          color: getColor('slate.300'),
        },
      },
    },
  };

  return (
    <>
      <h1 className="mt-2 text-2xl font-medium intro-y mb-5">Emissions</h1>

      <Box title='Active Subscriptions' shadow>
        <FormWrapper className="mt-3">
          <InputGroup>
            <FormLabel info='10€ / charger / month'>
              Emission Management
            </FormLabel>
            <FormSwitch name='emissionsEnabled' checked={emissionsEnabled} onChange={onChangeEmissions}/>
          </InputGroup>
        </FormWrapper>
      </Box>

      <Box title="Overview" shadow disabled={!emissionsEnabled} description="Problem: Collecting emission data is complex, time consuming and costly. Solution: Constantly monitor your charging infrastructure and file for carbon credits with one click.">
        <div className="flex flex-col md:flex-row md:items-center mb-5 mt-5">
          <div className="flex">
            <div>
              <div className="text-lg font-medium text-primary dark:text-slate-300 xl:text-xl">203 MWh</div>
              <div className="mt-0.5 text-slate-500">Total energy</div>
            </div>
            <div className="w-px h-12 mx-4 border border-r border-dashed border-slate-200 dark:border-darkmode-300 xl:mx-5"></div>
            <div>
              <div className="text-lg font-medium text-slate-500 xl:text-xl">65t CO2</div>
              <div className="mt-0.5 text-slate-500">Emissions submittable</div>
            </div>
            <div className="w-px h-12 mx-4 border border-r border-dashed border-slate-200 dark:border-darkmode-300 xl:mx-5"></div>
            <div>
              <div className="text-lg font-medium text-slate-500 xl:text-xl">670€ / t CO2</div>
              <div className="mt-0.5 text-slate-500">Quote price</div>
            </div>
          </div>
        </div>
        <Chart type="line" data={data} options={options} height={"275"} width={'auto'}/>

        <AlertComponent className="mt-5 flex">
          <Lucide icon={'CalendarClock'} />
          <p className='ml-3'>The data shown above is an example and this feature will be available soon. You will not be billed in the meantime and informed properly before it is made available.</p>
        </AlertComponent>
      </Box>
      
      <Box title="Carbon Credits" shadow disabled={!emissionsEnabled}>
        <AlertComponent className="mt-5 flex">
          <Lucide icon={'CalendarClock'} />
          <p className='ml-3'>This feature will be available soon. You will not be billed in the meantime and informed properly before it is made available.</p>
        </AlertComponent>
      </Box>
    </>
  );
}

export default EmissionsPage;
