import Box from 'components/Box';
import Button from 'components/Button';
import { FormInline, FormInput, FormLabel, FormWrapper } from 'components/Form';
import Lucide from 'components/Lucide';
import { ReadChargerDTO } from 'openapi';
import { EnvironmentContext } from 'providers/envProvider';
import { useContext } from 'react';

const ChargerConnectivity = ({ entity }: { entity?: ReadChargerDTO | null }) => {
  const { appBaseUrl } = useContext(EnvironmentContext);

  if (entity == null) {
    return <>No Data available.</>
  }

  const queryParams = {
    chargerName: entity!.name!,
    chargerLocation: entity!.location!,
    chargepointId: entity!.chargepointId!,
    chargerModel: entity!.model!,
  };
  const queryString = new URLSearchParams(queryParams).toString();
  const connectionInstructionUrl = appBaseUrl + '/connection-instruction/?' + queryString;

  const onViewConnectionInstruction = () => {
    window.open(connectionInstructionUrl, '_blank', 'noreferrer');
  };

  const onPrintConnectionInstruction = () => {
    window.open(connectionInstructionUrl + '&shouldPrint=true', '_blank', 'noreferrer');
  };

  const onShareConnectionInstruction = () => {
    const emailSubject = `Connect your Charger ${entity!.name!} to Chargd!`;
    const emailBody = "Check out this link to connect your charger to Chargd and start smart monitoring and management! \n\n" + connectionInstructionUrl + "\n\n\nYour Chargd-Team";
    window.open('mailto:?' + new URLSearchParams({subject: emailSubject, body: emailBody}).toString(), '_blank');
  };

  return (
    <Box title="Connectivity">
      <div className="mt-5">
        <FormWrapper onSubmit={console.log}>
          <FormInline>
            <FormLabel>
              Connection Status
            </FormLabel>
            <FormInput name="connectionStatus" value={entity?.status!.toUpperCase()} readOnly/>
          </FormInline>
          <FormInline>
            <FormLabel>
              Chargepoint ID
            </FormLabel>
            <FormInput name="chargepointId" value={entity?.chargepointId!} readOnly/>
          </FormInline>
        </FormWrapper>
        
        <Button variant="outline-primary" className="px-4 py-3 xl:mr-3 w-full mt-10" onClick={onShareConnectionInstruction}>
          <Lucide icon='Share' className="mr-2"/>
          Share Connection Instruction
        </Button>
        <Button variant="outline-primary" className="px-4 py-3 xl:mr-3 w-full mt-5" onClick={onPrintConnectionInstruction}>
          <Lucide icon='Printer' className="mr-2"/>
          Print Connection Instruction
        </Button>
        <Button variant="outline-primary" className="px-4 py-3 xl:mr-3 w-full mt-5" onClick={onViewConnectionInstruction}>
          <Lucide icon='View' className="mr-2"/>
          View Connection Instruction
        </Button>
      </div>
    </Box>
  );
};

export default ChargerConnectivity;
