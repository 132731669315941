import { Table } from 'components/Table';
import ActionCell from 'components/Table/ActionCell';
import { ConnectorApi, ReadConnectorDTO } from 'openapi';
import { customToast } from 'utils/customToast';

const connectorApi = new ConnectorApi();

const ConnectorList = ({ connectors }: { connectors: ReadConnectorDTO[] }) => {

  if (!connectors || connectors.length == 0) {
    return <>No Data Found</>
  }

  const columns = [
    {
      label: 'Connector ID',
      field: 'connectorId',
    },
    {
      label: 'Status',
      field: 'status',
    },
    {
      label: 'Current Power',
      field: 'currentPower',
    },
    {
      label: 'Current Energy',
      field: 'currentEnergy',
    },
    {
      label: 'Current Temperature',
      field: 'currentTemperature',
    },
    {
      label: 'Unlock',
      field: 'unlock',
    },
  ];

  const data = connectors?.map((connector) => {
    const action = async () => {
      try {
        let result = await connectorApi.unlockConnector({
          id: connector.id!,
        });
        customToast("Connector unlocked", "Unplugging should now be possible");
      }
      catch (ex) {
        customToast("An error has occured", "Could not unlock connector", "failure");
      }
    };

    return {
      key: connector.id!,
      connectorId: connector.connectorId,
      status: connector.status,
      currentPower: connector.currentPower,
      currentEnergy: connector.currentEnergy,
      currentTemperature: connector.currentTemperature,
      unlock: ActionCell({ title: "Unlock", icon: "Unlock", action})
    };
  });

  return (
    <div className="col-span-12 overflow-auto intro-y lg:overflow-visible">
      <Table data={data} columns={columns} className="border-spacing-y-[10px] border-separate -mt-2" />
    </div>
  );
};

export default ConnectorList;
