/* tslint:disable */
/* eslint-disable */
/**
 * Backend.Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ChargerStatus } from './ChargerStatus';
import {
    ChargerStatusFromJSON,
    ChargerStatusFromJSONTyped,
    ChargerStatusToJSON,
} from './ChargerStatus';
import type { ReadConnectorDTO } from './ReadConnectorDTO';
import {
    ReadConnectorDTOFromJSON,
    ReadConnectorDTOFromJSONTyped,
    ReadConnectorDTOToJSON,
} from './ReadConnectorDTO';

/**
 * 
 * @export
 * @interface ReadChargerDTO
 */
export interface ReadChargerDTO {
    /**
     * 
     * @type {string}
     * @memberof ReadChargerDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadChargerDTO
     */
    chargepointId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReadChargerDTO
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReadChargerDTO
     */
    model?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReadChargerDTO
     */
    location?: string | null;
    /**
     * 
     * @type {ChargerStatus}
     * @memberof ReadChargerDTO
     */
    status?: ChargerStatus;
    /**
     * 
     * @type {string}
     * @memberof ReadChargerDTO
     */
    group?: string | null;
    /**
     * 
     * @type {Array<ReadConnectorDTO>}
     * @memberof ReadChargerDTO
     */
    connectors?: Array<ReadConnectorDTO> | null;
}

/**
 * Check if a given object implements the ReadChargerDTO interface.
 */
export function instanceOfReadChargerDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ReadChargerDTOFromJSON(json: any): ReadChargerDTO {
    return ReadChargerDTOFromJSONTyped(json, false);
}

export function ReadChargerDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReadChargerDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'chargepointId': !exists(json, 'chargepointId') ? undefined : json['chargepointId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'model': !exists(json, 'model') ? undefined : json['model'],
        'location': !exists(json, 'location') ? undefined : json['location'],
        'status': !exists(json, 'status') ? undefined : ChargerStatusFromJSON(json['status']),
        'group': !exists(json, 'group') ? undefined : json['group'],
        'connectors': !exists(json, 'connectors') ? undefined : (json['connectors'] === null ? null : (json['connectors'] as Array<any>).map(ReadConnectorDTOFromJSON)),
    };
}

export function ReadChargerDTOToJSON(value?: ReadChargerDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'chargepointId': value.chargepointId,
        'name': value.name,
        'model': value.model,
        'location': value.location,
        'status': ChargerStatusToJSON(value.status),
        'group': value.group,
        'connectors': value.connectors === undefined ? undefined : (value.connectors === null ? null : (value.connectors as Array<any>).map(ReadConnectorDTOToJSON)),
    };
}

