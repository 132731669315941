/* tslint:disable */
/* eslint-disable */
/**
 * Backend.Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReadBillingInformationDTO
 */
export interface ReadBillingInformationDTO {
    /**
     * 
     * @type {string}
     * @memberof ReadBillingInformationDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadBillingInformationDTO
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReadBillingInformationDTO
     */
    vatNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReadBillingInformationDTO
     */
    address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReadBillingInformationDTO
     */
    billingEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReadBillingInformationDTO
     */
    creditCardLast4Digits?: string | null;
}

/**
 * Check if a given object implements the ReadBillingInformationDTO interface.
 */
export function instanceOfReadBillingInformationDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ReadBillingInformationDTOFromJSON(json: any): ReadBillingInformationDTO {
    return ReadBillingInformationDTOFromJSONTyped(json, false);
}

export function ReadBillingInformationDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReadBillingInformationDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'vatNumber': !exists(json, 'vatNumber') ? undefined : json['vatNumber'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'billingEmail': !exists(json, 'billingEmail') ? undefined : json['billingEmail'],
        'creditCardLast4Digits': !exists(json, 'creditCardLast4Digits') ? undefined : json['creditCardLast4Digits'],
    };
}

export function ReadBillingInformationDTOToJSON(value?: ReadBillingInformationDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'vatNumber': value.vatNumber,
        'address': value.address,
        'billingEmail': value.billingEmail,
        'creditCardLast4Digits': value.creditCardLast4Digits,
    };
}

