import { Menu } from 'store/sideMenuSlice';
import { slideUp, slideDown } from 'utils/helper';
import { Dispatch, SetStateAction } from 'react';
import { NavigateFunction } from 'react-router-dom';

interface Location {
  pathname: string | null;
  forceActiveMenu?: string;
}

export interface FormattedMenu extends Menu {
  active?: boolean;
  activeDropdown?: boolean;
  subMenu?: FormattedMenu[];
}

// Setup side menu
const findActiveMenu = (subMenu: Menu[], location: Location): boolean => {
  let match = false;
  subMenu.forEach((item) => {
    if (
      ((location.forceActiveMenu !== undefined && item.pathname === location.forceActiveMenu) ||
        (location.forceActiveMenu === undefined && item.pathname !== undefined && location.pathname?.includes(item.pathname))) &&
      !item.ignore
    ) {
      match = true;
    } else if (!match && item.subMenu) {
      match = findActiveMenu(item.subMenu, location);
    }
  });
  return match;
};

const nestedMenu = (menu: Array<Menu | 'divider'>, location: Location) => {
  const formattedMenu: Array<FormattedMenu | 'divider'> = [];
  menu.forEach((item) => {
    if (typeof item !== 'string') {
      const menuItem: FormattedMenu = {
        ...item,
      };
      menuItem.active =
        ((location.forceActiveMenu !== undefined && menuItem.pathname === location.forceActiveMenu) ||
          (location.forceActiveMenu === undefined && menuItem.pathname !== undefined && location.pathname?.includes(menuItem.pathname)) ||
          (menuItem.subMenu && findActiveMenu(menuItem.subMenu, location))) &&
        !menuItem.ignore;

      if (menuItem.subMenu) {
        menuItem.activeDropdown = findActiveMenu(menuItem.subMenu, location);

        // Nested menu
        const subMenu: Array<FormattedMenu> = [];
        nestedMenu(menuItem.subMenu, location).map((menu) => typeof menu !== 'string' && subMenu.push(menu));
        menuItem.subMenu = subMenu;
      }

      formattedMenu.push(menuItem);
    } else {
      formattedMenu.push(item);
    }
  });

  return formattedMenu;
};

const linkTo = (menu: FormattedMenu, navigate: NavigateFunction) => {
  if (menu.subMenu) {
    menu.activeDropdown = !menu.activeDropdown;
  } else {
    if (menu.pathname !== undefined) {
      navigate(menu.pathname);
    }
  }
};

const mobileLinkTo = (
  menu: FormattedMenu,
  navigate: NavigateFunction,
  setActiveMobileMenu: Dispatch<SetStateAction<boolean>>
) => {
  if (menu.subMenu) {
    menu.activeDropdown = !menu.activeDropdown;
  } else {
    if (menu.pathname !== undefined) {
      setActiveMobileMenu(false);

      navigate(menu.pathname);
    }
  }
};

const enter = (el: HTMLElement) => {
  slideDown(el, 300);
};

const leave = (el: HTMLElement) => {
  slideUp(el, 300);
};

export { nestedMenu, linkTo, mobileLinkTo, enter, leave };
