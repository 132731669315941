import clsx from 'clsx';

function MenuDivider<C extends React.ElementType>(props: { as?: C } & React.ComponentPropsWithoutRef<C>) {
  const { className, ...computedProps } = props;
  const Component = props.as || 'div';

  return (
    <Component
      {...computedProps}
      className={clsx([props.className, 'w-full h-px bg-white/[0.08] relative'])}
    ></Component>
  );
}

export default MenuDivider;
