import { FormLabel, FormSwitch, FormWrapper, InputGroup } from 'components/Form';
import LoadManagementSection from './components/LoadManagementSection';
import SmartChargingSection from './components/SmartChargingSection';
import { ChangeEvent, useEffect, useState } from 'react';
import Box from 'components/Box';
import { ErrorCode, ResponseError, SubscriptionApi, SubscriptionModule } from 'openapi';
import { customToast } from 'utils/customToast';
import { useNavigate } from 'react-router-dom';
import { handleSubscriptionChange } from 'utils/subscriptions';

const subscriptionApi = new SubscriptionApi();

export default function OptimizationPage() {
  const [loadManagementEnabled, setLoadManagementEnabled] = useState<boolean>(false);
  const [smartChargingEnabled, setSmartChargingEnabled] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    const getActiveSubscriptions = async () => {
      try {
        const activeSubscriptions = await subscriptionApi.getActiveSubscriptions();
        const subscriptionModules = activeSubscriptions.map(x => x.module);

        if (subscriptionModules.includes(SubscriptionModule.LoadManagement)) {
          setLoadManagementEnabled(true);
        }
        if (subscriptionModules.includes(SubscriptionModule.SmartCharging)) {
          setSmartChargingEnabled(true);
        }
      }
      catch (ex) {
        // ignore
      }
    }

    getActiveSubscriptions();
  }, []);

  const onChangeLoadManagement = async () => {
    await handleSubscriptionChange(loadManagementEnabled, setLoadManagementEnabled, SubscriptionModule.LoadManagement, navigate);
  }

  const onChangeSmartCharging = async () => {
    await handleSubscriptionChange(smartChargingEnabled, setSmartChargingEnabled, SubscriptionModule.SmartCharging, navigate);
  }

  return (
    <>
      <h1 className="mt-2 text-2xl font-medium intro-y mb-5">Optimization</h1>

      <Box title='Active Subscriptions' shadow>
        <FormWrapper className="mt-3">
          <InputGroup>
            <FormLabel info='25€ / charger / month'>
              Load Management
            </FormLabel>
            <FormSwitch name='loadManagementEnabled' checked={loadManagementEnabled} onChange={onChangeLoadManagement}/>
          </InputGroup>
          <InputGroup>
            <FormLabel info='25€ / charger / month'>
              Smart Charging
            </FormLabel>
            <FormSwitch name='smartChargingEnabled' checked={smartChargingEnabled} onChange={onChangeSmartCharging}/>
          </InputGroup>
        </FormWrapper>
      </Box>

      <LoadManagementSection disabled={!loadManagementEnabled}/>
      <SmartChargingSection disabled={!smartChargingEnabled}/>
    </>
  );
}
