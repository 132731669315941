import { twMerge } from 'tailwind-merge';
import AlertComponent from 'components/Alert';
import Lucide from 'components/Lucide';
import { FormInput, FormLabel, FormSwitch, FormWrapper, InputGroup } from 'components/Form';
import Button from 'components/Button';
import Box from 'components/Box';
import { AccessApi, ChargerGroupApi, PowerLimitApi, ReadAccessRuleDto } from 'openapi';
import { useState, useEffect } from 'react';
import { customToast } from 'utils/customToast';
import LoadingIcon from 'components/LoadingIcon';
import { useForm } from 'react-hook-form';

const accessApi = new AccessApi();

function AccessManagementSection({ disabled } : { disabled: boolean}) {
  const [accessRules, setAccessRules] = useState<ReadAccessRuleDto[]>([]);
  const useFormOverride = useForm();
  
  const getAccessRules = async () => {
    try {
      setAccessRules(await accessApi.getAllAccessRules());
    }
    catch (ex) {
      customToast("An error has occured", "Data was not fetched from the server", "failure");
    }
  }

  useEffect(() => {
    getAccessRules();
  }, []);

  const createAccessRule = async (idTag: string, name: string) => {
    if (!name || !idTag) return;
    
    try {
      let result = await accessApi.createAccessRule({
        createAccessRuleDto: {
          idTag,
          name,
        }
      });
      customToast("Success", "User was added");
      setAccessRules([...accessRules, {
        id: result.id,
        name,
        idTag,
      }]);
      useFormOverride.reset({idTag: "", name: ""});
    }
    catch {
      customToast("An error has occured", "Could not add user", "failure");
    }
  }

  const deleteAccessRule = async (id: string) => {
    try {
      await accessApi.deleteAccessRule({
        id
      });
      customToast("Success", "User was removed");
      setAccessRules(accessRules.filter(ar => ar.id != id));
    }
    catch {
      customToast("An error has occured", "Could not remove user", "failure");
    }
  }

  return (
    <>
      <Box title="Access Management" shadow disabled={disabled}
        description="Problem: Companies want to provide access to charging stations only to limited users. Solution: Register and use Id Tags that allow charging sessions to be started.">
        {accessRules?.length > 0 && <h1 className="mt-2 text-xl font-medium mb-5">Registered users</h1>}
        {accessRules?.length <= 0 && <h1 className="mt-2 text-xl font-medium">Register a new user</h1>}
        <div className="grid grid-cols-11 mt-5 w-1/2">
          {accessRules?.length > 0 && <>
            <span className="text-lg font-black col-span-5 mb-2">
              Name
            </span>
            <span className="text-lg font-black col-span-5 mb-2">
              Id Tag
            </span>
          </>}
          
          {accessRules.map(ar => (<>
            <span className="font-black col-span-5 flex items-center mb-2">
              {ar.name!}
            </span>
            <span className="font-black col-span-5 flex items-center mb-2">
              {ar.idTag!}
            </span>
            <Button type="button" variant='danger' className='col-span-1 mb-2' disabled={disabled} onClick={() => deleteAccessRule(ar.id!)}>
              <Lucide icon="Trash"></Lucide>
            </Button>
          </>))}

          <FormWrapper className={twMerge(["col-span-11", accessRules?.length > 0 && "mt-5"])} onSubmit={(formData) => createAccessRule(formData.idTag!, formData.name!)} useFormOverride={useFormOverride}>
            <InputGroup>
            <FormInput name='name' type='text' placeholder="Name" disabled={disabled}/>
            <FormInput name='idTag' type='text' placeholder="Id Tag" className='ml-2' disabled={disabled}/>
            <Button type="submit" variant='primary' className='ml-4' disabled={disabled}>
              <Lucide icon="Plus"></Lucide>
            </Button>
            </InputGroup>
          </FormWrapper>
        </div>
      </Box>
    </>
  );
}

export default AccessManagementSection;
