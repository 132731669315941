/* tslint:disable */
/* eslint-disable */
/**
 * Backend.Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ReadChartPointDto,
} from '../models';
import {
    ReadChartPointDtoFromJSON,
    ReadChartPointDtoToJSON,
} from '../models';

/**
 * 
 */
export class LoadCurveApi extends runtime.BaseAPI {

    /**
     */
    async getLoadCurveLast24hRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ReadChartPointDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("Bearer", []);
        }

        const response = await this.request({
            path: `/load-curves/last-24h`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReadChartPointDtoFromJSON));
    }

    /**
     */
    async getLoadCurveLast24h(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ReadChartPointDto>> {
        const response = await this.getLoadCurveLast24hRaw(initOverrides);
        return await response.value();
    }

}
