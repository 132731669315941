import { useContext } from 'react';
import { formInlineContext } from '../FormInline';
import { InputGroupContext } from '../InputGroup';
import { twMerge } from 'tailwind-merge';
import { useFormContext } from 'react-hook-form';
import FormInputWrapper from 'components/Form/FormInputWrapper';

interface FormInputProps extends React.ComponentPropsWithoutRef<'input'> {
  name: string;
  formInputSize?: 'sm' | 'lg';
  rounded?: boolean;
}

const FormInput: React.FC<FormInputProps> = (props) => {
  const formInline = useContext(formInlineContext);
  const inputGroup = useContext(InputGroupContext);
  const methods = useFormContext();
  const { name, value, formInputSize, rounded, ...computedProps } = props;

  return (
    <FormInputWrapper>
      <input
        {...computedProps}
        {...methods.register(name)}
        value={value}
        className={twMerge([
          'block px-4 py-3 border intro-x login__input min-w-full',
          'disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent',
          '[&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent',
          'transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80',
          props.formInputSize == 'sm' && 'text-xs py-1.5 px-2',
          props.formInputSize == 'lg' && 'text-lg py-1.5 px-4',
          props.rounded && 'rounded-full',
          formInline && 'flex-1',
          inputGroup && 'rounded-none [&:not(:first-child)]:border-l-transparent first:rounded-l last:rounded-r z-10',
          props.className,
        ])}
      />
    </FormInputWrapper>
  );
};

export default FormInput;
