import AlertComponent from "components/Alert";
import Box from "components/Box";
import { FormLabel, FormSwitch, FormWrapper, InputGroup } from "components/Form";
import Lucide from "components/Lucide";
import { Table } from "components/Table";
import BooleanCell from "components/Table/BooleanCell";
import { SubscriptionApi, SubscriptionModule } from "openapi";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { handleSubscriptionChange } from "utils/subscriptions";

const subscriptionApi = new SubscriptionApi();

function ForwardingPage() {
  const [forwardingEnabled, setForwardingEnabled] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    const getActiveSubscriptions = async () => {
      try {
        const activeSubscriptions = await subscriptionApi.getActiveSubscriptions();
        const subscriptionModules = activeSubscriptions.map(x => x.module);

        if (subscriptionModules.includes(SubscriptionModule.Forwarding)) {
          setForwardingEnabled(true);
        }
      }
      catch (ex) {
        // ignore
      }
    }

    getActiveSubscriptions();
  }, []);

  const onChangeForwarding = async () => {
    await handleSubscriptionChange(forwardingEnabled, setForwardingEnabled, SubscriptionModule.Forwarding, navigate);
  }

  const columns = [
    {
      label: 'Address',
      field: 'address',
    },
    {
      label: 'Enabled',
      field: 'enabled',
    },
  ];

  const data = [
    {
      key: "1",
      address: "ocpp.application1.com",
      enabled: BooleanCell({ value: true}),
    },
    {
      key: "2",
      address: "ocpp.application2.ai",
      enabled: BooleanCell({ value: false}),
    },
    {
      key: "3",
      address: "application3.de/ocpp",
      enabled: BooleanCell({ value: true}),
    }
  ];

  return (
    <>
      <h1 className="mt-2 text-2xl font-medium intro-y mb-5">Forwarding</h1>

      <Box title='Active Subscriptions' shadow>
        <FormWrapper className="mt-3">
          <InputGroup>
            <FormLabel info='5€ / charger / month'>
              OCPP Forwarding
            </FormLabel>
            <FormSwitch name='emissionsEnabled' checked={forwardingEnabled} onChange={onChangeForwarding}/>
          </InputGroup>
        </FormWrapper>
      </Box>

      <Box title="Forwarding Destinations" disabled={!forwardingEnabled} shadow description="Problem: Using other solutions alongside Chargd is not supported by most charging devices. 
        Solution: Forward OCPP communication to other applications.">
        <Table data={data} columns={columns} className="border-spacing-y-[10px] border-separate -mt-2" />

        <AlertComponent className="mt-5 flex">
          <Lucide icon={'CalendarClock'} />
          <p className='ml-3'>This feature will be available soon. You will not be billed in the meantime and informed properly before it is made available.</p>
        </AlertComponent>
      </Box>
    </>
  );
}

export default ForwardingPage;
