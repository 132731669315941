import ChargerAddPage from 'pages/chargers/ChargerAddPage';
import ChargerPage from 'pages/chargers/ChargerPage';
import ChargersPage from 'pages/chargers/ChargersPage';
import DashboardPage from 'pages/dashboard/DashboardPage';
import LoginPage from 'pages/login/LoginPage';
import LogoutPage from 'pages/logout/LogoutPage';
import OptimizationPage from 'pages/optimization/Optimization';
import BillingPage from 'pages/billing/BillingPage';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import AuthenticationProtected from './ProtectedRoute';
import { MenuProvider } from './menuProvider';

import ConnectionInstructionPage from 'pages/connectionInstruction/ConnectionInstructionPage';
import NotificationsPage from 'pages/notifications/Notifications';
import { Toaster } from 'react-hot-toast';
import { useAuth0 } from '@auth0/auth0-react';
import FleetPage from 'pages/fleet/FleetPage';
import EmissionsPage from 'pages/emissions/EmissionsPage';
import ForwardingPage from 'pages/forwarding/ForwardingPage';
import AccessPage from 'pages/access/AccessPage';

function DefaultRoute() {
	const { isAuthenticated } = useAuth0();

	if (isAuthenticated) {
		return <Navigate to="/dashboard" />;
	}

	return <Navigate to="/login" />
}

export function ApplicationRouter() {
	return (
		<>
			<Toaster />
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<DefaultRoute />} />
					<Route path="/login" element={<LoginPage />} />
					<Route element={<AuthenticationProtected />}>
						<Route element={<MenuProvider />}>
							<Route path="/dashboard" element={<DashboardPage />} />
							<Route path="/optimization" element={<OptimizationPage />} />
							<Route path="/fleet" element={<FleetPage />} />
							<Route path="/chargers" element={<ChargersPage />} />
							<Route path="/chargers/add" element={<ChargerAddPage />} />
							<Route path="/chargers/:id" element={<ChargerPage />} />
							<Route path="/notifications" element={<NotificationsPage />} />
							<Route path="/emissions" element={<EmissionsPage />} />
							<Route path="/forwarding" element={<ForwardingPage />} />
							<Route path="/access" element={<AccessPage />} />
							<Route path="/billing" element={<BillingPage />} />
							<Route path="/logout" element={<LogoutPage />} />
						</Route>
					</Route>
					<Route path="connection-instruction" element={<ConnectionInstructionPage />} />
				</Routes>
			</BrowserRouter>
		</>
	);
}