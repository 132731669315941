import Box from "components/Box";
import { EnvironmentContext } from "providers/envProvider";
import { useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import logoTextUrl from 'assets/images/logo_text_white.png';
import { FormInline, FormInput, FormLabel, FormWrapper } from "components/Form";

export default function ConnectionInstructionPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const ocppEndpoint = useContext(EnvironmentContext).ocppEndpoint;

  const chargerName = searchParams.get("chargerName");
  const chargerLocation = searchParams.get("chargerLocation");
  const chargepointId = searchParams.get("chargepointId");
  const chargerModel = searchParams.get("chargerModel");
  const shouldPrint = searchParams.get("shouldPrint");

  useEffect(() => {
    if (shouldPrint) {
      // set a delay to fully render the page
      setTimeout(() => {
        window.print();
      }, 1000);
    }
  }, []);

  return (
    <>
      <div className="xl:w-1/2 m-auto">
        <div className="flex place-content-center mt-10">
          <img alt="Chargd." className="w-40 pl-2 lg:w-80" src={logoTextUrl} />
        </div>
        <h1 className="text-3xl font-black text-white font-sans mt-10">Connection Instruction</h1>
        <Box title="Step 1 - Locate Charger" className="mt-5">
          <FormWrapper onSubmit={console.log} entity={{}} className="mt-5">
            <p>The first step is to locate the charger. It is important to find the right charger
              so that our system tracks the desired charger</p>
            <FormInline className="mt-0">
              <FormLabel>
                Name
              </FormLabel>
              <FormInput name="chargerName" value={chargerName!}/>
            </FormInline>
            <FormInline className="mt-0">
              <FormLabel>
                Model
              </FormLabel>
              <FormInput name="chargerModel" value={chargerModel!}/>
            </FormInline>
            <FormInline className="mt-0">
              <FormLabel>
                Location
              </FormLabel>
              <FormInput name="chargerLocation" value={chargerLocation!}/>
            </FormInline>
          </FormWrapper>
        </Box>
        <Box title="Step 2 - Connect Charger" className="lg:mt-10 mt-5 mb-10">
          <FormWrapper onSubmit={console.log} entity={{}} className="mt-5">
            <p>Please follow the next steps precisely to ensure a reliable connection. Your charger supports OCPP
              and needs to establish a connection to our OCPP server. We will send you a notification once the
              charger has established a connection to our system.
            </p>
            <FormInline className="mt-1">
              <FormLabel>
                Ocpp Endpoint
              </FormLabel>
              <FormInput name="ocppEndpoint" value={ocppEndpoint}/>
            </FormInline>

            <FormInline className="mt-1">
              <FormLabel>
                Ocpp Port
              </FormLabel>
              <FormInput name="ocppPort" value={80}/>
            </FormInline>

            <FormInline className="mt-1">
              <FormLabel>
                ChargepointId
              </FormLabel>
              <FormInput name="chargepointId" value={chargepointId!}/>
            </FormInline>

            <FormInline className="mt-1">
              <FormLabel>
                Full Url
              </FormLabel>
              <FormInput name="fullUrl" value={ocppEndpoint + ':80/' + chargepointId}/>
            </FormInline>
          </FormWrapper>
        </Box>
      </div>
    </>
  );
}
