/* tslint:disable */
/* eslint-disable */
/**
 * Backend.Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const SubscriptionModule = {
    LoadManagement: 'loadManagement',
    SmartCharging: 'smartCharging',
    Forwarding: 'forwarding',
    Costs: 'costs',
    Emissions: 'emissions',
    Fleet: 'fleet',
    Access: 'access'
} as const;
export type SubscriptionModule = typeof SubscriptionModule[keyof typeof SubscriptionModule];


export function SubscriptionModuleFromJSON(json: any): SubscriptionModule {
    return SubscriptionModuleFromJSONTyped(json, false);
}

export function SubscriptionModuleFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriptionModule {
    return json as SubscriptionModule;
}

export function SubscriptionModuleToJSON(value?: SubscriptionModule | null): any {
    return value as any;
}

