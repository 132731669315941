/* tslint:disable */
/* eslint-disable */
/**
 * Backend.Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ChargingSessionStatus } from './ChargingSessionStatus';
import {
    ChargingSessionStatusFromJSON,
    ChargingSessionStatusFromJSONTyped,
    ChargingSessionStatusToJSON,
} from './ChargingSessionStatus';
import type { ReadChargerDTO } from './ReadChargerDTO';
import {
    ReadChargerDTOFromJSON,
    ReadChargerDTOFromJSONTyped,
    ReadChargerDTOToJSON,
} from './ReadChargerDTO';
import type { ReadConnectorDTO } from './ReadConnectorDTO';
import {
    ReadConnectorDTOFromJSON,
    ReadConnectorDTOFromJSONTyped,
    ReadConnectorDTOToJSON,
} from './ReadConnectorDTO';

/**
 * 
 * @export
 * @interface ReadChargingSessionDTO
 */
export interface ReadChargingSessionDTO {
    /**
     * 
     * @type {string}
     * @memberof ReadChargingSessionDTO
     */
    id?: string;
    /**
     * 
     * @type {ReadChargerDTO}
     * @memberof ReadChargingSessionDTO
     */
    charger?: ReadChargerDTO;
    /**
     * 
     * @type {ReadConnectorDTO}
     * @memberof ReadChargingSessionDTO
     */
    connector?: ReadConnectorDTO;
    /**
     * 
     * @type {ChargingSessionStatus}
     * @memberof ReadChargingSessionDTO
     */
    status?: ChargingSessionStatus;
    /**
     * 
     * @type {Date}
     * @memberof ReadChargingSessionDTO
     */
    startTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ReadChargingSessionDTO
     */
    endTime?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof ReadChargingSessionDTO
     */
    meterStart?: number;
    /**
     * 
     * @type {number}
     * @memberof ReadChargingSessionDTO
     */
    meterStop?: number | null;
}

/**
 * Check if a given object implements the ReadChargingSessionDTO interface.
 */
export function instanceOfReadChargingSessionDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ReadChargingSessionDTOFromJSON(json: any): ReadChargingSessionDTO {
    return ReadChargingSessionDTOFromJSONTyped(json, false);
}

export function ReadChargingSessionDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReadChargingSessionDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'charger': !exists(json, 'charger') ? undefined : ReadChargerDTOFromJSON(json['charger']),
        'connector': !exists(json, 'connector') ? undefined : ReadConnectorDTOFromJSON(json['connector']),
        'status': !exists(json, 'status') ? undefined : ChargingSessionStatusFromJSON(json['status']),
        'startTime': !exists(json, 'startTime') ? undefined : (new Date(json['startTime'])),
        'endTime': !exists(json, 'endTime') ? undefined : (json['endTime'] === null ? null : new Date(json['endTime'])),
        'meterStart': !exists(json, 'meterStart') ? undefined : json['meterStart'],
        'meterStop': !exists(json, 'meterStop') ? undefined : json['meterStop'],
    };
}

export function ReadChargingSessionDTOToJSON(value?: ReadChargingSessionDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'charger': ReadChargerDTOToJSON(value.charger),
        'connector': ReadConnectorDTOToJSON(value.connector),
        'status': ChargingSessionStatusToJSON(value.status),
        'startTime': value.startTime === undefined ? undefined : (value.startTime.toISOString()),
        'endTime': value.endTime === undefined ? undefined : (value.endTime === null ? null : value.endTime.toISOString()),
        'meterStart': value.meterStart,
        'meterStop': value.meterStop,
    };
}

