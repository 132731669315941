import Box from "components/Box";
import NotificationSettingsForm from "./components/NotificationSettingsForm";
import AlertComponent from "components/Alert";
import NotificationList from "./components/NotificationList";

function NotificationsPage() {
  return (
		<>
			<div className="flex items-center mt-8 intro-y">
				<h2 className="mr-auto text-lg font-medium">Notifications</h2>
			</div>

			<div className="grid grid-cols-11 mt-5 pb-20 gap-x-6">
				<div className="col-span-11 xl:col-span-4 intro-y">
					<NotificationList />
				</div>
				<div className="col-span-11 lg:col-span-4 intro-y">
					<NotificationSettingsForm />
				</div>
			</div>
		</>
	)
}

export default NotificationsPage;