import AlertComponent from "components/Alert";
import Box from "components/Box";
import Button from "components/Button";
import { ChargerApi, ReadChargerDTO, UpdateChargerDTO } from "openapi";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { customToast } from "utils/customToast";
import ChargerConnectivity from "./components/ChargerConnectivity";
import ChargerForm from "./components/ChargerForm";
import ConnectorList from "./components/ConnectorList";

const chargerApi = new ChargerApi();

function ChargerPage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const useFormOverride = useForm();
  const [charger, setCharger] = useState<ReadChargerDTO | null>(null);
  
  useEffect(() => {
    const getCharger = async () => {
      try {
        const chargerFetched = await chargerApi.getCharger({ id: id! });
        setCharger(chargerFetched);
        useFormOverride.reset(chargerFetched);
      }
      catch (ex) {
        customToast("An error has occured", "Data was not fetched from the server", "failure");
        navigate("/dashboard");
      }
    }

    getCharger();
  }, []);

  const onSubmit = async (data: UpdateChargerDTO) => {
    try {
      let result = await chargerApi.updateCharger({
        id: id!,
        updateChargerDTO: data,
      });
      setCharger(data);
      customToast("Charger Successfully updated", "Data stored to the server");

    }
    catch (ex) {
      customToast("An error has occured", "Data was not stored to the server", "failure");
    }
  }

  const onDelete = async () => {
    try {
      let result = await chargerApi.deleteCharger({
        id: id!,
      });
      navigate("/chargers");
      customToast("Charger Successfully deleted", "All associated data is gone");
    }
    catch (ex) {
      customToast("An error has occured", "Charger could not be deleted", "failure");
    }
  }

  return (
    <>
      <div className="flex items-center mt-8 intro-y">
        <h2 className="mr-auto text-lg font-medium">Manage Charger: {charger?.name} @ {charger?.location}</h2>
      </div>

      <div className="grid grid-cols-11 mt-5 pb-20 gap-x-6">
        <div className="col-span-11 xl:col-span-6 intro-y">
          <ChargerForm onSubmit={onSubmit} useFormOverride={useFormOverride}/>
        </div>
        <div className="col-span-11 xl:col-span-5 intro-y">
          <ChargerConnectivity entity={charger}/>
        </div>
        <Box title="Connector List" className="col-span-11 intro-y mt-10">
          <ConnectorList connectors={charger?.connectors!} />
        </Box>
        <Box title="Danger Zone" className="col-span-11 xl:col-span-5 intro-y mt-10">
          <AlertComponent>
            Deleting the charger will result in permanent data loss and the charger on site will not be able to connect.
          </AlertComponent>
          <Button type="button" variant="danger" className="px-4 py-3 xl:mr-3 mt-5 right-0 w-full" onClick={onDelete}>
            Delete Charger
          </Button>
        </Box>
      </div>
    </>
  );
}

export default ChargerPage;
