import { twMerge } from 'tailwind-merge';
import ManagementDemoChart from './DemoChart';
import AlertComponent from 'components/Alert';
import Lucide from 'components/Lucide';
import Box from 'components/Box';

function SmartChargingSection({ disabled } : { disabled: boolean}) {
  return (
    <>
      <Box title="Smart Charging" shadow disabled={disabled}
        description="Problem: Non optimized charging can cost up to 15% more if volatile electricity prices are not considered. Solution: Charge vehicles the most when prices are 
          and avoid costly peak hours.">
        <div className="intro-y mt-2 relative before:content-[''] before:w-[90%] before:shadow-[0px_3px_20px_#0000000b] before:bg-slate-50 before:h-full before:mt-3 before:absolute before:rounded-md before:mx-auto before:inset-x-0 before:dark:bg-darkmode-400/70">
          <div className="p-5 box">
            <div className="flex flex-col md:flex-row md:items-center">
              <div className="flex">
                <div>
                  <div className="text-lg font-medium text-primary dark:text-slate-300 xl:text-xl">€8.450</div>
                  <div className="mt-0.5 text-slate-500">Estimated Actual Costs</div>
                </div>
                <div className="w-px h-12 mx-4 border border-r border-dashed border-slate-200 dark:border-darkmode-300 xl:mx-5"></div>
                <div>
                  <div className="text-lg font-medium text-slate-500 xl:text-xl">€7.420</div>
                  <div className="mt-0.5 text-slate-500">Estimated Optimised Costs</div>
                </div>
              </div>
            </div>
            <div className='border-t border-slate-200 mt-2 '>
              <p className="text-slate-500 py-2">Compare the <strong>actual</strong> to the <strong>cost optimised</strong> power usage.</p>
            </div>
            <div
              className={twMerge([
                'relative',
                "before:content-[''] before:block before:absolute before:w-16 before:left-0 before:top-0 before:bottom-0 before:ml-10 before:mb-7 before:bg-gradient-to-r before:from-white before:via-white/80 before:to-transparent before:dark:from-darkmode-600",
                "after:content-[''] after:block after:absolute after:w-16 after:right-0 after:top-0 after:bottom-0 after:mb-7 after:bg-gradient-to-l after:from-white after:via-white/80 after:to-transparent after:dark:from-darkmode-600",
              ])}
            >
              <ManagementDemoChart height={275} className="mt-2 -mb-2" datasetVariant='planned_optimised'/>
            </div>
          </div>
        </div>
        <AlertComponent className="mt-5 flex">
          <Lucide icon={'CalendarClock'} />
          <p className='ml-3'>The data shown above is an example and this feature will be available soon. You will not be billed in the meantime and informed properly before it is made available.</p>
        </AlertComponent>
      </Box>
    </>
  );
}

export default SmartChargingSection;
