import React, { useContext } from 'react';
import envLoader from '@beam-australia/react-env';
import { useAuth0 } from '@auth0/auth0-react';
import { Configuration, DefaultConfig } from 'openapi';
import { EnvironmentContext } from './envProvider';


function BackendProvider({ children }: React.PropsWithChildren) {
  const { getAccessTokenSilently } = useAuth0();
	const { backendBaseUrl } = useContext(EnvironmentContext);

	const getAccessToken = async () => {
		const token = await getAccessTokenSilently();
		return 'Bearer ' + token;
	}

	DefaultConfig.config = new Configuration({
		accessToken: getAccessToken,
		basePath: backendBaseUrl,
	})

  return ( 
		<>
    	{children}
		</>
  );
}

export default BackendProvider;
