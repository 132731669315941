import clsx from 'clsx';
import FormInformation from './components/FormInformation';
import LoginForm from './components/LoginForm';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate, useLocation } from 'react-router-dom';

export default function Login() {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
	const location = useLocation();

	if (isAuthenticated) {
		return <Navigate to="/dashboard" state={{ from: location }} replace />
	}
  else {
    loginWithRedirect();
    return <></>;
  }
}
