import Lucide from '../Lucide';
import { twMerge } from 'tailwind-merge';

export default function BooleanCell({ value }: { value: boolean }) {
  return (
    <div className={twMerge(['flex items-center', value ? 'text-success' : 'text-danger'])}>
      <Lucide icon="CheckSquare" className="w-4 h-4 mr-2" />
      {value ? 'Yes' : 'No'}
    </div>
  );
}
