/* tslint:disable */
/* eslint-disable */
/**
 * Backend.Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateChargerDTO
 */
export interface UpdateChargerDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateChargerDTO
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateChargerDTO
     */
    group?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateChargerDTO
     */
    model?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateChargerDTO
     */
    location?: string | null;
}

/**
 * Check if a given object implements the UpdateChargerDTO interface.
 */
export function instanceOfUpdateChargerDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateChargerDTOFromJSON(json: any): UpdateChargerDTO {
    return UpdateChargerDTOFromJSONTyped(json, false);
}

export function UpdateChargerDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateChargerDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'group': !exists(json, 'group') ? undefined : json['group'],
        'model': !exists(json, 'model') ? undefined : json['model'],
        'location': !exists(json, 'location') ? undefined : json['location'],
    };
}

export function UpdateChargerDTOToJSON(value?: UpdateChargerDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'group': value.group,
        'model': value.model,
        'location': value.location,
    };
}

