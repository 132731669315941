import { twMerge } from 'tailwind-merge';
import AlertComponent from 'components/Alert';
import Lucide from 'components/Lucide';
import { FormInput, FormLabel, FormSwitch, FormWrapper, InputGroup } from 'components/Form';
import Button from 'components/Button';
import Box from 'components/Box';
import { ChargerGroupApi, PowerLimitApi, ReadChargerGroupDTO, ReadListChargerGroupDTO, ReadPowerLimitDto } from 'openapi';
import { useState, useEffect } from 'react';
import { customToast } from 'utils/customToast';
import LoadingIcon from 'components/LoadingIcon';
import { useForm } from 'react-hook-form';

const chargerGroupApi = new ChargerGroupApi();
const powerLimitApi = new PowerLimitApi();

function LoadManagementSection({ disabled } : { disabled: boolean}) {
  const [chargerGroups, setChargerGroups] = useState<ReadChargerGroupDTO[]>([]);
  const [powerLimits, setPowerLimits] = useState<ReadPowerLimitDto[]>([]);
  const [finishedLoading, setFinishedLoading] = useState<boolean>(false);
  
  const getChargerGroups = async () => {
    try {
      setChargerGroups(await chargerGroupApi.getAllChargerGroups());
    }
    catch (ex) {
      customToast("An error has occured", "Data was not fetched from the server", "failure");
    }
  }

  const getPowerLimits = async () => {
    try {
      setPowerLimits(await powerLimitApi.getPowerLimits());
    }
    catch (ex) {
      customToast("An error has occured", "Data was not fetched from the server", "failure");
    }
  }

  const loadData = async () => {
    await Promise.allSettled([getChargerGroups(), getPowerLimits()]);
    setFinishedLoading(true);
  }

  useEffect(() => {
    loadData();
  }, []);

  const createOrUpdatePowerLimit = async ({ limitValue: powerLimit }: { limitValue: number }, group: string) => {
    try {
      await powerLimitApi.createOrUpdatePowerLimit({
        createOrUpdatePowerLimitDto: {
          group,
          powerLimit,
        }
      });
      await loadData();
      customToast("Success", "Power limit for group " + group + " was set");
    }
    catch {
      customToast("An error has occured", "Could not set power limit", "failure");
    }
  }

  const deletePowerLimit = async (id: string) => {
    try {
      await powerLimitApi.deletePowerLimit({
        id
      });
      await loadData();
      customToast("Success", "Power limit was deleted");
    }
    catch {
      customToast("An error has occured", "Could not set power limit", "failure");
    }
  }

  return (
    <>
      <Box title="Load Management" shadow disabled={disabled}
        description="Problem: Utilities charge high penalties for energy overconsumption. They are often as high as 30% of your
          energy bill without you knowing. Solution: Avoid penalties by limiting the power consumption of your charging infrastructure.
          Power is distributed equally across multiple chargers.">
            <FormWrapper>
            <InputGroup>
              <FormLabel className="text-lg font-black">
                Charger Group
              </FormLabel>
              <FormLabel className="text-lg font-black">
                Power Limit in [kW]
              </FormLabel>
            </InputGroup>
            </FormWrapper>
        {finishedLoading ? chargerGroups.map((cg, i) => {
          const powerLimitEntity = powerLimits.find(pl => pl.group == cg.name);
          const powerLimit = powerLimitEntity?.powerLimit;
          return (<FormWrapper className={twMerge(["mt-3"])} onSubmit={(formData) => createOrUpdatePowerLimit(formData, cg.name!)} entity={{ limitValue: powerLimit }} key={i}>
            <InputGroup>
              <FormLabel className={twMerge([!powerLimit && "opacity-50"])}>
                {cg.name!}
              </FormLabel>
              <FormInput name='limitValue' type='number' disabled={disabled}/>
              <Button type="submit" variant='primary' className='ml-2' disabled={disabled}>
                <Lucide icon="Plus"></Lucide>
              </Button>
              { powerLimit && <Button type="button" variant='danger' className='ml-2' disabled={disabled} onClick={() => deletePowerLimit(powerLimitEntity.id!)}>
                <Lucide icon="Trash"></Lucide>
              </Button> }
            </InputGroup>
          </FormWrapper>);
        }) : <Lucide icon="Loader2" />}
      </Box>
    </>
  );
}

export default LoadManagementSection;
