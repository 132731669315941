import { FormattedMenu, mobileLinkTo } from 'utils/menu';
import { Dispatch, SetStateAction } from 'react';
import clsx from 'clsx';
import { toRaw } from 'utils/helper';
import Lucide from 'components/Lucide';
import { useNavigate } from 'react-router';


function Menu(props: {
  menu: FormattedMenu;
  formattedMenuState: [(FormattedMenu | 'divider')[], Dispatch<SetStateAction<(FormattedMenu | 'divider')[]>>];
  level: 'first' | 'second' | 'third';
  setActiveMobileMenu: Dispatch<SetStateAction<boolean>>;
}) {
  const navigate = useNavigate();
  const [formattedMenu, setFormattedMenu] = props.formattedMenuState;

  return (
    <a
      href={props.menu.subMenu ? '#' : props.menu.pathname}
      className={clsx([
        'h-[50px] flex items-center text-white',
        props.level == 'first' && 'px-6',
        props.level != 'first' && 'px-4',
      ])}
      onClick={(event) => {
        event.preventDefault();
        mobileLinkTo(props.menu, navigate, props.setActiveMobileMenu);
        setFormattedMenu(toRaw(formattedMenu));
      }}
    >
      <div>
        <Lucide icon={props.menu.icon} />
      </div>
      <div className="flex items-center w-full ml-3">
        {props.menu.title}
        {props.menu.subMenu && (
          <div
            className={clsx([
              'transition ease-in duration-100 ml-auto',
              props.menu.activeDropdown && 'transform rotate-180',
            ])}
          >
            <Lucide icon="ChevronDown" className="w-5 h-5" />
          </div>
        )}
      </div>
    </a>
  );
}

export default Menu;
