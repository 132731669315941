import React from 'react';
import { FormProvider, UseFormReturn, useForm } from 'react-hook-form';
import { cleanFormData } from 'utils/formHelper';
import classNamesJoin from 'utils/helper';

type FormProps = {
  children: React.ReactNode;
  onSubmit?: (x: any) => void;
  entity?: object | null;
  className?: string;
  useFormOverride?: UseFormReturn<{}, any, undefined>;
};

const Form: React.FC<FormProps> = ({ entity, children, onSubmit, className, useFormOverride }) => {
  const formOptions = {
    defaultValues: { ...entity },
  };

  const methods = useFormOverride ?? useForm(formOptions);

  const handleFormSubmit = (updatedEntity: any) => {
    if (onSubmit)
      onSubmit(cleanFormData(updatedEntity));
    methods.reset(updatedEntity);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleFormSubmit)} className={classNamesJoin(className)}>
        {children}
      </form>
    </FormProvider>
  );
};

export default Form;
