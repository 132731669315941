import AlertComponent, { Variant } from "components/Alert";
import Box from "components/Box";

const SupportedEvents = [
	{
		name: "New Charger Connected",
		description: "A new charger has established a connection for the first time",
		color: "bg-sky-500",
		variant: "outline-primary",
	},
	{
		name: "Charger Disconnected",
		description: "A charger lost connection to Chargd",
		color: "bg-danger",
		variant: "outline-danger",
	},
	{
		name: "Charger Overheating",
		description: "The measured temperature inside a charger has reached a critical level",
		color: "bg-danger",
		variant: "outline-danger",
	}
];

export default function NotificationList () {
	return (
		<>
			<Box title="Notifications">
				<p className="mt-5 mb-5">We are continuously working on expanding our notification capability by adding more events. The following events are sent as notifications to the provided email:</p>
				{ SupportedEvents.map(ev => (
					<AlertComponent className="mt-2" variant={ev.variant as Variant}>
						<div className="flex">
							<span className="relative flex h-3 w-3 mr-2 pt-1">
								<span className={"animate-ping absolute inline-flex h-full w-full rounded-full opacity-75 " + ev.color}></span>
								<span className={"relative inline-flex rounded-full h-3 w-3 " + ev.color}></span>
							</span>
							<span className="font-bold relative flex">
								{ev.name}
							</span>
						</div>
						<p className="font-light">{ev.description}.</p>
					</AlertComponent>
				))}
			</Box>
		</>
	)
}