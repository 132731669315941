
import { ChartData, ChartOptions } from 'chart.js/auto';
import { getColor } from 'utils/colors';
import { selectColorScheme } from 'store/colorSchemeSlice';
import { selectDarkMode } from 'store/darkModeSlice';
import { useAppSelector } from 'store/hooks';
import { useMemo } from 'react';
import Chart from 'components/Chart';
import { DeepPartial } from 'chart.js/dist/types/utils';

interface MainProps extends React.ComponentPropsWithoutRef<'canvas'> {
  width?: number | string;
  height?: number | string;
  data: ChartData;
  options?: DeepPartial<ChartOptions>;
}

function Main({ width = 'auto', height = 'auto', className, data, options }: MainProps) {
  const colorScheme = useAppSelector(selectColorScheme);
  const darkMode = useAppSelector(selectDarkMode);

  const defaultOptions: ChartOptions = useMemo(() => {
    return {
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          ticks: {
            font: {
              size: 12,
            },
            color: getColor('slate.500', 0.8),
          },
          grid: {
            display: false,
            drawBorder: false,
          },
        },
        y: {
          ticks: {
            font: {
              size: 12,
            },
            color: getColor('slate.500', 0.8),
            callback: function (value) {
              return '$' + value;
            },
          },
          grid: {
            color: darkMode ? getColor('slate.500', 0.3) : getColor('slate.300'),
            borderDash: [2, 2],
            drawBorder: false,
          },
        },
      },
    };
  }, [colorScheme, darkMode]);

  return <Chart type="line" width={width} height={height} data={data} options={{ ...defaultOptions, ...options }} className={className} />;
}

export default Main;
